import { HttpClient } from "@angular/common/http";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from "@ngx-translate/core";
import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {HATranslation} from "../models/interfaces";
import {ServerSideRequest, ServerSideResponse} from "@herdia-common/lib-herdia-datatable";
import {HATranslationURLs} from "../models/enums";

@Injectable({
  providedIn: 'root'
})
export class LibHerdiaAppTranslateCustomLoader implements TranslateLoader {
  constructor(private http: LibHttpClientApiService) {

  }

  getTranslationTable(request: ServerSideRequest): Observable<ServerSideResponse> {
    return this.http.post<ServerSideRequest, ServerSideResponse>(HATranslationURLs.TABLE, request);
  }
  getTranslation(lang: string): Observable<any> {
    const translations = localStorage.getItem("herdiaTranslations");
    if (translations !== null) {
      let selectedLang = "FR";
      switch (lang) {
        default:
          selectedLang = "FR";
          break;
        case "en":
        case "EN":
        case "en-EN":
        case "EN-EN":
        case "en-GB":
        case "EN-GB":
          selectedLang = "EN";
          break;
        case "de":
        case "DE":
        case "de-DE":
        case "de-DE":
          selectedLang = "DE";
          break;
      }
      return of(JSON.parse(translations).translations[selectedLang]);
    }
    return of({KEY: 'value'});
  }
}

export class LibHerdiaAppMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}
