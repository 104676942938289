import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { HaPageCategory, PageManagement } from '../../shared/models/interfaces';
import { PageUrls } from '../../shared/service/api.service';
import { PageService } from '../../shared/service/page.service';

@Component({
  selector: 'app-import-page-modal',
  templateUrl: './import-page-modal.component.html',
  styleUrls: ['./import-page-modal.component.scss']
})
export class ImportPageModalComponent implements OnInit {

  availableCategories: HaPageCategory[] = [];
  selectedCategory: number = 0;
  fileName = '';
  file!: File;

  @Output() pageImported = new EventEmitter();

  constructor(private apiService: LibHttpClientApiService, private pageService: PageService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.apiService.get<PageManagement>(PageUrls.INDEX).subscribe(r => {
      if (r) {
        this.availableCategories = r.Categories;
      }
    });
  }

  onValidate() {
    const formData = new FormData();
    formData.append(this.fileName, this.file);
    formData.append("categoryId", this.selectedCategory.toString());

    this.pageService.importConfig(formData)?.subscribe(res => {
      if (res) {
        this.activeModal.close();
        this.pageImported.emit();
      }
      else
        console.error(`Erreur lors de l'import`);
    });
  }

  onFileSelected(event: any) {
    if (event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      this.file = event.target.files[0];
    }
  }
}
