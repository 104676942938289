import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AddPageModalComponent } from '../../add-page-modal/add-page-modal.component';
import { ImportPageModalComponent } from '../../add-page-modal/import-page-modal/import-page-modal.component';
import { ExportPageConfigModalComponent } from '../../export-page-config-modal/export-page-config-modal.component';
import { AddPageRequest, ExportPageRequest, RemovePageRequest, PageManagement, DuplicatePageRequest, EditPageRequest, ExportPageResponse, HaPage, HaPageVM, Role } from '../models/interfaces';
import { PageUrls, RoleUrls } from './api.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  pageModalId = 'pageModal';
  pageModalTitle = '';
  pageId: number | undefined;
  inputTitle: string | undefined;
  inputDesc: string | undefined;
  inputCategory: string | undefined;
  duplicatePageModalId = 'duplicatePageModal';

  hasCategory = false;
  pageInfo: PageManagement = { Categories: [], Pages: [] };

  newPageSubject = new BehaviorSubject<{ categoryId: number } | null>(null);
  dropPageSubject = new BehaviorSubject<{ id: number } | null>(null);
  duplicatePageSubject = new BehaviorSubject<{ id: number } | null>(null);
  editPageSubject = new BehaviorSubject<{ pageId: number | undefined } | null>(null);

  initPageSubject = new Subject<number | undefined>();
  initDuplicatePage = new Subject<void>();
  modalRef!: NgbModalRef;

  closeImportConfigSubject = new Subject<void>();

  constructor(private apiSvc: LibHttpClientApiService, private modalService: NgbModal, private router: Router) { }

  add(page: AddPageRequest) {
    this.apiSvc.post<AddPageRequest, HaPage>(PageUrls.ADD, page)?.subscribe(r => {
      if (r) {
        this.newPageSubject.next(page);
        this.modalRef.close();
        this.router.navigate(['/home'], { queryParams: { id: r.Id } });
      }
      else
        console.error(`Erreur lors de la modification de la page ${page.pageTitle}`);
    });
  }

  getPage(id?: number): Observable<HaPageVM>{
    return this.apiSvc.get<HaPageVM>(PageUrls.GET_PAGE + "/" + id);
  }

  getPages(): Observable<HaPage[]> {
    return this.apiSvc.get<HaPage[]>(PageUrls.GET_PAGES);
  }

  edit(page: EditPageRequest) {
    this.apiSvc.put<EditPageRequest, { success: boolean }>(PageUrls.EDIT, page)?.subscribe(r => {
      if (r)
        this.editPageSubject.next({ pageId: page.pageId });
      else
        console.error(`Erreur lors de l\'ajout de la page ${page.pageTitle}`);
    });
  }

  remove(page: RemovePageRequest) {
    const body = { pageId: page.id };
    return this.apiSvc.delete<any, boolean>(PageUrls.DELETE, body)?.subscribe(r => {
      if (r) {
        this.dropPageSubject.next(page);
      }
      else {
        console.error(`Erreur lors de la supression de la page ${page.id}`);
      }
    });
  }

  duplicate(page: DuplicatePageRequest) {
    const body = { pageId: page.id };
    return this.apiSvc.post<any, boolean>(PageUrls.DUPLICATE, body)?.subscribe(r => {
      if (r) {
        this.duplicatePageSubject.next(page);
      }
      else {
        console.error(`Erreur lors de la duplication de la page ${page.id}`);
      }
    });
  }
  getCategories(): PageManagement {
    this.apiSvc.get<{ pageManagement: PageManagement, success: boolean }>(PageUrls.INDEX).subscribe(r => {
      if (r.success) {
        this.pageInfo.Categories = r.pageManagement.Categories;
        this.pageInfo.Pages = r.pageManagement.Pages;
      }
    });
    return this.pageInfo;
  }

  getRoles() {
    return this.apiSvc.get<Role[]>(RoleUrls.GET_ALL);
  }

  openAddModal(): void {
    this.modalRef = this.modalService.open(AddPageModalComponent);
  }

  openExportModal(): void {
    this.modalRef = this.modalService.open(ExportPageConfigModalComponent);
  }

  exportConfig(param: ExportPageRequest): Observable<ExportPageResponse> {
    return this.apiSvc.post<ExportPageRequest, ExportPageResponse>(PageUrls.EXPORT, param);
  }

  openAndInitImportConfig() : void {
    this.modalRef = this.modalService.open(ImportPageModalComponent);
    this.modalRef.componentInstance.pageImported.subscribe(() => { this.closeImportConfigSubject.next(); });
  }

  importConfig(formData: FormData): Observable<ExportPageResponse> {
    return this.apiSvc.post<any, ExportPageResponse>(PageUrls.IMPORT, formData);
  }
}
