<div class="card card-primary">
  <div class="card-header">
    <h3 class="card-title">
      <fa-icon icon="check-circle"></fa-icon>&nbsp;<lib-translate [key]="'app-user-view-title'"></lib-translate>
    </h3>
    <div class="align-items-baseline card-tools d-flex">
      <button type="button" class="btn btn-tool" data-card-widget="maximize" data-toggle="tooltip" title="Plein écran">
        <fa-icon icon="expand"></fa-icon>
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-lg-5 mt-4">
        <div class="d-flex justify-content-center m-3">
          <img *ngIf="model.Img; else defaultImg" [src]="model.Img" alt="Image profile" />
          <ng-template #defaultImg>
            <img src="../../../assets/img/Default_pfp.svg" />
          </ng-template>
        </div>
        <div class="d-flex justify-content-center">
          <h5>{{model.UserName}}</h5>
        </div>
        <div class="d-flex justify-content-center">
          <span class="">{{model.Email}}</span>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="row m-4">
          <div class="col-lg-3 m-2"><lib-translate [key]="'app-user-firstname-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{model.FirstName}}</div>
          <div class="col-lg-3 m-2"><lib-translate [key]="'app-user-lastname-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{model.LastName}}</div>
          <div class="col-lg-3 m-2"><lib-translate [key]="'app-user-language-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{model.LanguageCode}}</div>
          <div class="col-lg-3 m-2"><lib-translate [key]="'app-user-phone-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{model.Phone}}</div>
          <div class="col-lg-3 m-2"><lib-translate [key]="'app-user-dateformat-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{dateFormat.value}}</div>
          <!--<div class="col-lg-3 m-2"><lib-translate [key]="'app-user-role-label'"></lib-translate>&nbsp; :</div><div class="col-lg-6 m-2">{{model.Role}}</div>-->
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-primary" (click)="onReturn()"><lib-translate [key]="'app-back-to-list'"></lib-translate></button>
        <button type="button" class="btn btn-primary returnBtn"><lib-translate [key]="'app-user-edit-btn'"></lib-translate></button>
      </div>
    </div>
  </div>
</div>
