import { ModuleLoader } from 'src/app/shared/static/ModuleLoader';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LibDynamicComponentService } from '@herdia-common/lib-dynamic-components';
import { HaPageRow, HaPageRowVM } from '../../shared/models/interfaces';
import { AddCardModalComponent } from './add-card-modal/add-card-modal.component';
import { AddPredefinedCardModalComponent } from './add-predefined-card-modal/add-predefined-card-modal.component';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  @Input() row: HaPageRowVM | undefined;
  @Input() maxWidth = 12;
  @Output() rowChange: EventEmitter<HaPageRowVM> = new EventEmitter<HaPageRowVM>();


  cardActionsList: { action:string, label:string }[] = [
    {
      action: "addCard",
      label: "lbl-add-card"
    },
    {
      action: "addPredefinedCard",
      label: "lbl-add-predefined-card"
    }
  ];

  constructor(private dynamicComponentService: LibDynamicComponentService, public modalService: NgbModal) {
  }

  ngOnInit(): void {

  }

  addCard(item: { action:string, label:string }): void {
    if (item.action === "addCard") {
      var componentByPackage: { package: string, selector: string, minWidth: number, disabled: boolean }[] = []; 
      let selectors: string[] = [];
      ModuleLoader.getAvailableCardSelectors(this.dynamicComponentService).then(r => {
        componentByPackage = r;
        const modalRef = this.modalService.open(AddCardModalComponent);
          modalRef.componentInstance.availableDynamicCardSelector = componentByPackage;
          modalRef.componentInstance.cardBase.pageRowId = this.row?.Id;
          modalRef.componentInstance.cardBase.cardWidth = this.maxWidth;
          modalRef.componentInstance.maxWidth = this.maxWidth;
          modalRef.componentInstance.cardAdded.subscribe(() => { this.update(); });
      });
    }
    if (item.action === "addPredefinedCard") {
      const modalRef = this.modalService.open(AddPredefinedCardModalComponent);
      modalRef.componentInstance.cardBase.pageRowId = this.row?.Id;
      modalRef.componentInstance.cardBase.cardWidth = this.maxWidth;
      modalRef.componentInstance.cardAdded.subscribe(() => { this.update(); });
    }
  }

  update(): void {
    this.rowChange.emit(this.row);
  }
}
