import { Injectable, TemplateRef } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { NotificationURLs, ToastType } from '../models/enums';
import { PersistentNotification } from './../models/interfaces';

@Injectable({ providedIn: 'root' })
export class PersistentNotificationService {
  public Notifications: PersistentNotification[] = [];

  constructor(private apiService: LibHttpClientApiService) {}

  acknowledgeNotifications(notificationId: string): void {
    this.apiService.get(NotificationURLs.ACKNOWLEDGE_NOTIFICATION, {"id": notificationId}).subscribe(e => {
      this.Notifications= this.Notifications.filter(item => item.id !== notificationId);
    });
  }
}
