import { Directive } from '@angular/core';

@Directive({
  selector: '[appMaskPhone]'
})
export class MaskPhoneDirective {

  constructor() { }

}
