import { Injectable, TemplateRef } from '@angular/core';
import { ToastType } from '../models/enums';
import { ToastMessage } from './../models/interfaces';

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: any[] = [];

  show(message: ToastMessage): void {
    let options: any = {};
    switch (message.Type) {
      default:
      case ToastType.Standard:
        break;
      case ToastType.Danger:
        options.className = "bg-danger text-light";
        options.delay = 5000;
        break;
      case ToastType.Success:
        options.className = "bg-success text-light";
        options.delay = 5000;
        break;
    }
    this.toasts.push({ message, ...options });
  }

	remove(toast: any): void {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clear(): void {
		this.toasts.splice(0, this.toasts.length);
	}
}
