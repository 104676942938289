import { Injectable } from '@angular/core';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CachingService {

  isLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private apiSvc: LibHttpClientApiService) { }

  loadCache() {
    return new Observable<{ progress: number, label: string }>(SUBSCRIBER => {
      // TODO: Rendre la liste des caches dans un tableau pour que les applications "clientes" de herdiaApp puissent choisir ce qui ce sera caché au login.
    //  this.isLoadingSubject.next(true);
    //  SUBSCRIBER.next({ progress: 0, label: 'Loading import dates...' });

    //  this.apiSvc.post<any, { IMPORT_DATE: Date }>(CachingDataUrl.GET_IMPORT_DATE_LIST_DATAS, {})?.subscribe(r => {
    //    const importDate = moment.utc(r.IMPORT_DATE).toDate();
    //    SUBSCRIBER.next({ progress: 10, label: 'Loading portfolios...' });
    //    portfoliosList(importDate);
    //  });

    //  const portfoliosList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra",
    //      AllPortfoliosLine: "TRUE"
    //    };
    //    this.apiSvc.post<any, any>(CachingDataUrl.GET_PORTFOLIOS_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetPortfoliosListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 20, label: 'Loading external funds...' });
    //      externalFundList(importDt);
    //    });
    //  };

    //  const externalFundList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_EXTERNAL_FUND_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetExternalFundListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 30, label: 'Loading asset managers...' });
    //      assetManagerList(importDt);
    //    });
    //  };

    //  const assetManagerList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_ASSET_MANAGER_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetAssetManagerListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 40, label: 'Loading business units...' });
    //      businessUnitList(importDt);
    //    });
    //  }

    //  const businessUnitList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_BUSINESS_UNIT_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetBusinessUnitListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 50, label: 'Loading feed centers...' });
    //      feeCenterList(importDt);
    //    });
    //  }

    //  const feeCenterList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_FEE_CENTER_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetFeeCenterListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 60, label: 'Loading property managers...' });
    //      managerList(importDt);
    //    });
    //  }

    //  const managerList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_MANAGER_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetManagerListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 70, label: 'Loading agencies...' });
    //      agencyList(importDt);
    //    });
    //  }

    //  const agencyList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      User: "Alhambra"
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_AGENCY_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetAgencyListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 80, label: 'Loading owners...' });
    //      ownersList(importDt);
    //    });
    //  }

    //  const ownersList = (importDt: Date) => {
    //    const param = {
    //      ImportDate: importDt
    //    };
    //    return this.apiSvc.post<any, any>(CachingDataUrl.GET_OWNERS_LIST_DATAS, param)?.subscribe(r => {
    //      this.warmCache("GetOwnersListDatas", param, r);
    //      SUBSCRIBER.next({ progress: 100, label: 'Loading translations...' });
    //      setTimeout(translationHashKey, 500);
    //    });
    //  }

    //  const translationHashKey = () => {
    //    return this.apiSvc.getText(TranslationUrl.GET_TRANSLATION_HASH, { languageCode: this.translateSvc.currentLocal })?.subscribe(hashKey => {
    //      const storedHasKey = this.translateSvc.getStoredHashkey();
    //      if (!storedHasKey || storedHasKey !== hashKey)
    //      {
    //        translationList(hashKey as string);
    //      } else {
    //        this.isLoadingSubject.next(false);
    //        SUBSCRIBER.complete();
    //      }
    //    });
    //  }

    //  const translationList = (hashKey: string) => {
    //    const param = {
    //      Code: null,
    //      LanguageCode: this.translateSvc.currentLocal,
    //      IdReportTemplate: 0,
    //      TablixName: null
    //    }
    //    return this.apiSvc.post<any, [{CODE:string, LABEL:string}]>(TranslationUrl.GET_TRANSLATION_DATAS, param)?.subscribe(translations => {
    //      let transMap = new Map<string, string>();
    //      for (const transItem of translations)
    //        transMap.set(transItem.CODE, transItem.LABEL)

    //      const transData: LocalStorageTranslation = {
    //        hash: hashKey,
    //        datas: transMap
    //      }

    //      this.translateSvc.storeData(transData);
    //      this.isLoadingSubject.next(false);
    //      SUBSCRIBER.complete();
    //    });
    //  };
      SUBSCRIBER.next({ progress: 100, label: 'Loading caches done...' });
      this.isLoadingSubject.next(false);
      SUBSCRIBER.complete();
    });
  }

  warmCache(methodName: string, param: any, response: any) {
    //const fingerprint = Md5.hashStr(JSON.stringify({ method: methodName, params: param }));
    //let currentDate = moment().utc().valueOf();
    //localStorage.setItem("alhFilter_" + fingerprint, JSON.stringify({ data: response.responseContent, date: currentDate }));
  }
}
