import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApplicationFeatures } from '../models/enums';
import { ApplicationSpecificPropertiesUrls } from './api.service';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { LibHerdiaAppEnvironmentService } from '@herdia-common/lib-herdia-app-environment';
import { environment } from 'src/environments/environment';
import { LibEditModeService } from '@herdia-common/lib-edit-mode';
import { DOCUMENT } from '@angular/common';
import { CardThemeDesignerService } from './card-theme-designer.service';
import { ThemeVariables } from '../models/interfaces';
import { LibDynamicComponentsEventBusService } from '@herdia-common/lib-dynamic-component-event-bus';
import { PropertyDefinition } from '@herdia-common/lib-card-generic-crud';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  editModeId = 'editMode';
  editModeSub: BehaviorSubject<boolean>;
  hasRightPanel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  applicationName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  herdiaAppBuildId: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationIconBase64: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationBackgroundBase64: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationDefaultTheme: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationRightPanelPackageName: BehaviorSubject<string> = new BehaviorSubject<string>("");
  applicationUserProperties: BehaviorSubject<PropertyDefinition[]> = new BehaviorSubject<PropertyDefinition[]>([]);


  constructor(@Inject(DOCUMENT) private document: Document, apiService: LibHttpClientApiService, private ThemeDesignerService: CardThemeDesignerService, public environmentService: LibHerdiaAppEnvironmentService, private editModeService: LibEditModeService, public applicationEventBus: LibDynamicComponentsEventBusService) {
    const editModeStr = localStorage.getItem(this.editModeId);
    environmentService.apiBaseUrl = environment.apiBaseUrl;

    if (editModeStr && editModeStr.toLocaleLowerCase() === 'true') {
      this.editModeSub = new BehaviorSubject<boolean>(true);
    } else
      this.editModeSub = new BehaviorSubject<boolean>(false);

    this.document.body.style.display = "none";

    apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_BACKGROUND).subscribe(r => {
      this.applicationBackgroundBase64.next(r);

      apiService.get<number[]>(ApplicationSpecificPropertiesUrls.GET_FEATURES).subscribe(f => {
        if (f.indexOf(ApplicationFeatures.RightPanel) !== -1)
          this.hasRightPanel.next(true);
      });

      apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_NAME).subscribe(r => {
        this.applicationName.next(r);
      });


      apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_RIGHTPANEL_PACKAGE_NAME).subscribe(r => {
        this.applicationRightPanelPackageName.next(r);
      })

      apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_ICON).subscribe(r => {
        this.applicationIconBase64.next(r);
      });
      apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_HERDIAAPP_API_BUILDID).subscribe(r => {
        this.herdiaAppBuildId.next(r);
      });

      apiService.get<string>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_DEFAULT_THEME).subscribe(r => {
        this.applicationDefaultTheme.next(r);
        let DefaultTheme = {
          PrimaryColor: r[0],
          SecondaryColor: r[1],
          CustomFontSize: r[2],
          LeftNavbarColor: r[3],
          TopNavbarColor: r[4]
        } as ThemeVariables

        this.setTheme(DefaultTheme);
      })

      apiService.get<PropertyDefinition[]>(ApplicationSpecificPropertiesUrls.GET_APPLICATION_USER_PROPERTIES).subscribe(r => {
        this.applicationUserProperties.next(r);
      })

      this.document.body.style.display = "block";
    });

  }

  setTheme(Theme: ThemeVariables) {
    const actualTheme = localStorage.getItem('ActualTheme');
    if (actualTheme) {
      const label = actualTheme;
      this.ThemeDesignerService.showTheme(label).subscribe();
    }
    else {
      this.ThemeDesignerService.showCustomTheme(Theme);
    }
  }

  setEditMode() {
    //if (this.editModeSub.value !== editMode) {
    //  this.editModeSub.next(editMode);
      //localStorage.setItem(this.editModeId, `${editMode}`);
    this.editModeService.setEditMode();
    this.editModeSub.next(this.editModeService.getEditMode());
    //}
  }

  // Create an Image object from a base64-encoded string
  public base64ToImage(base64: string): HTMLImageElement {

    // Create an Image object
    const img = new Image();

    // Set the src attribute of the Image object to the URL of the parameter
    img.src = "data:image/png;base64," + base64;

    return img;
  }
}
