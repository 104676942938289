import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LibDynamicComponentsModule } from '@herdia-common/lib-dynamic-components';
import { LibConfirmBoxModule } from "@herdia-common/lib-confirm-box";
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AddRoutingModule } from './app-routing.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInitInterceptor } from './shared/interceptor/api-init.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingComponent } from './login/loading/loading.component';
import { appInitializer } from './shared/interceptor/appInitializer';
import { AccountService } from './shared/service/account.service';
import { CardBoxComponent } from './cards/card-box/card-box.component';
import { AddCardComponent } from './home/add-card/add-card.component';
import { CardConfigComponent } from './cards/card-config/card-config.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgSelectModule } from '@ng-select/ng-select';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateManagerComponent } from './translate-manager/translate-manager.component';
import { AddCardModalComponent } from './home/add-card/add-card-modal/add-card-modal.component';
import { RowsComponent } from './rows/rows.component';
import { AddPageModalComponent } from './add-page-modal/add-page-modal.component';
import { AddCategoryModalComponent } from './add-category-modal/add-category-modal.component';
import { SaveCardConfigComponent } from './cards/card-config/save-card-config/save-card-config.component';
import { LibHerdiaAppTranslateModule } from '@herdia-common/lib-herdia-app-translate';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { LibHerdiaAppMissingTranslationHandler, LibHerdiaAppTranslateCustomLoader } from './shared/service/translate.service';
import { ModuleLoader } from './shared/static/ModuleLoader';
import { LibHerdiaAppEnvironmentService } from "@herdia-common/lib-herdia-app-environment";
import { TaskSchedulerManagerComponent } from './task-scheduler-manager/task-scheduler-manager.component';
import { DataTablesModule } from 'angular-datatables';
import { AddEditTaskSchedulerComponent } from './task-scheduler-manager/add-edit-task-scheduler/add-edit-task-scheduler.component';
import { UsersComponent } from './user/users/users.component';
import { AddEditUserComponent } from './user/add-edit-user/add-edit-user.component';
import { ProfileComponent } from './user/profile/profile.component';
import { MaskPhoneDirective } from './shared/directive/mask-phone.directive';
import { RoleComponent } from './role/role.component';
import { AddEditRoleComponent } from './role/add-edit-role/add-edit-role.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { LibEditModeModule } from '@herdia-common/lib-edit-mode';
import { ToastNotificationComponent } from './toast-notification/toast-notification.component';
import { PersistentNotificationComponent } from './persistent-notification/persistent-notification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LibUploaderModule } from '@herdia-common/lib-uploader';
import { FileUploadModule } from 'ng2-file-upload';
import { LibIconPickerModule } from '@herdia-common/lib-icon-picker';
import { LibCronEditorModule } from '@herdia-common/lib-cron-editor';
import { UploaderManagerComponent } from './uploader-manager/uploader-manager.component';
import { DBConnectionManagerComponent } from './dbconnection-manager/dbconnection-manager.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { AddPredefinedCardModalComponent } from './home/add-card/add-predefined-card-modal/add-predefined-card-modal.component';
import { DragulaModule } from 'ng2-dragula';
import { CardThemeDesignerComponent } from './card-theme-designer/card-theme-designer.component';
import { LibDatatablePaginationModule } from "@herdia-common/lib-datatable-pagination";
import { EmailManagerComponent } from './application-email-manager/email-manager.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AddDbconnectionModalComponent } from './dbconnection-manager/add-dbconnection-modal/add-dbconnection-modal.component';
import { UserEmailManagerComponent } from './user-email-manager/user-email-manager.component';
import { DatatableUserMailRowActionComponent } from './user-email-manager/datatable-row-action/datatable-row-action.component';
import { DatatableAppMailRowActionComponent } from './application-email-manager/datatable-app-mail-row-action/datatable-app-mail-row-action.component';
import { PageUnauthorizedComponent } from './page-unauthorized/page-unauthorized.component';
import { LibSchedulerServiceModule } from '@herdia-common/lib-scheduler-service';
import { LibHerdiaDatatableModule } from '@herdia-common/lib-herdia-datatable';
import { LibCardHeaderCommonButtonsModule } from "@herdia-common/lib-card-header-common-buttons";
import { LibPropertyTypeInputModule } from "@herdia-common/lib-property-type-input";
import { FiledepositManagerComponent } from './filedeposit-manager/filedeposit-manager.component';
import { AddFiledepositModalComponent } from './filedeposit-manager/add-filedeposit-modal/add-filedeposit-modal.component';
import { EditFiledepositModalComponent } from './filedeposit-manager/edit-filedeposit-modal/edit-filedeposit-modal.component';
import { PreviewFiledepositModalComponent } from './filedeposit-manager/preview-filedeposit-modal/preview-filedeposit-modal.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { ExportCardConfigComponent } from './cards/card-config/export-card-config/export-card-config.component';
import { AddCardConfigurationModalComponent } from './home/add-card/add-card-modal/add-card-configuration-modal/add-card-configuration-modal.component';
import { ExportPageConfigModalComponent } from './export-page-config-modal/export-page-config-modal.component';
import { ImportPageModalComponent } from './add-page-modal/import-page-modal/import-page-modal.component';

registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeEn, 'en-GB');



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    LoadingComponent,
    CardBoxComponent,
    AddCardComponent,
    CardConfigComponent,
    ContentManagementComponent,
    TranslateManagerComponent,
    AddCardModalComponent,
    RowsComponent,
    AddPageModalComponent,
    AddCategoryModalComponent,
    SaveCardConfigComponent,
    ToastNotificationComponent,
    PersistentNotificationComponent,
    UsersComponent,
    AddEditUserComponent,
    ProfileComponent,
    MaskPhoneDirective,
    RoleComponent,
    AddEditRoleComponent,
    TaskSchedulerManagerComponent,
    AddEditTaskSchedulerComponent,
    ForgotPasswordComponent,
    UploaderManagerComponent,
    AddPredefinedCardModalComponent,
    EmailConfirmationComponent,
    CardThemeDesignerComponent,
    DBConnectionManagerComponent,
    EmailManagerComponent,
    AddPredefinedCardModalComponent,
    AddDbconnectionModalComponent,
    UserEmailManagerComponent,
    DatatableUserMailRowActionComponent,
    DatatableAppMailRowActionComponent,
    PageUnauthorizedComponent,
    FiledepositManagerComponent,
    AddFiledepositModalComponent,
    EditFiledepositModalComponent,
    PreviewFiledepositModalComponent,
    ExportCardConfigComponent,
    AddCardConfigurationModalComponent,
    ExportPageConfigModalComponent,
    ImportPageModalComponent
  ],
  imports: [
    BrowserModule,
    AddRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    NgSelectModule,
    //BrowserAnimationsModule,
    GoogleMapsModule,
    LibDynamicComponentsModule,
    ModuleLoader.getApplicationModule(),
    LibConfirmBoxModule,
    LibHerdiaAppTranslateModule,
    LibUploaderModule,
    LibCronEditorModule,
    LibSchedulerServiceModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LibHerdiaAppTranslateCustomLoader,
        deps: [HttpClient, LibHttpClientApiService]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LibHerdiaAppMissingTranslationHandler
      },
      useDefaultLang: false,
      isolate: false
    }),
    DataTablesModule,
    SocialLoginModule,
    LibEditModeModule,
    LibIconPickerModule,
    FileUploadModule,
    DragulaModule.forRoot(),
    LibDatatablePaginationModule,
    AngularEditorModule,
    LibHerdiaDatatableModule,
    LibCardHeaderCommonButtonsModule,
    FontAwesomeModule,
    FormsModule,
    LibPropertyTypeInputModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInitInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue:
      {
        autoLogin: false,
        providers:
        [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider
              ('456102707862-hu882fp9lir9oa7p6e8ta1mm3atsn3tg.apps.googleusercontent.com')
          }
          ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    NgbActiveModal,
    TranslatePipe,
    LibHerdiaAppEnvironmentService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
