import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from '../shared/service/app.service';
import { PersistentNotificationService } from '../shared/service/persistent-notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  editMode = false;
  hasRightPanel = false;
  sidebar_collapse_menu = false;
  sidebar_collapse_filter = false;

  constructor(private appSvc: AppService, public persistentNotificationService: PersistentNotificationService,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.appSvc.editModeSub.subscribe(mode => this.editMode = mode);
    this.appSvc.hasRightPanel.subscribe(rp => this.hasRightPanel = rp);
  }

  setEditMode() {
    this.appSvc.setEditMode();
  }

  sideBarAnim() {
    if (this.sidebar_collapse_menu == false) {
      this.document.body.classList.add('sidebar-collapse');
      this.sidebar_collapse_menu = true;
    }
    else {
      this.document.body.classList.remove('sidebar-collapse')
      this.sidebar_collapse_menu = false;
    }
  }

  sideBarFilterAnim() {
    if (this.sidebar_collapse_filter == false) {
      this.document.body.classList.add('control-sidebar-slide-open');
      this.sidebar_collapse_filter = true;
    }
    else {
      this.document.body.classList.remove('control-sidebar-slide-open')
      this.sidebar_collapse_filter = false;
    }
  }

}
