import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponentBase } from '../../../../shared/models/baseClasses';
import { CardService } from '../../../../shared/service/card.service';

@Component({
  selector: 'app-add-card-configuration-modal',
  templateUrl: './add-card-configuration-modal.component.html',
  styleUrls: ['./add-card-configuration-modal.component.scss']
})
export class AddCardConfigurationModalComponent extends FormComponentBase implements OnInit {

  fileName = '';
  file!: File;
  pageRowId: number = 0;

  @Input() set pageRow(value: number) {
    if (!value)
      return;

    this.pageRowId = value;
  }
  @Output() cardImported = new EventEmitter();


  constructor(private cardSvc: CardService, public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void { }

  onFileSelected(event: any) {
    if (event.target.files[0]) {
      this.fileName = event.target.files[0].name;
      this.file = event.target.files[0];
    }
  }

  import() {
    const formData = new FormData();
    formData.append(this.fileName, this.file);
    formData.append("pageRowId", this.pageRowId.toString());

    this.cardSvc.importConfig(formData)?.subscribe(res => {
      if (res) {
        this.activeModal.close();
        this.cardImported.emit();
      }
      else
        console.error(`Erreur lors de l'import`);
    });
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
