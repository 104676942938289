import { Component, OnInit, ViewChild } from '@angular/core';
import { HaPageCard } from "../shared/models/interfaces";


@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {


  ngOnInit() : void {}
}
