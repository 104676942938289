import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LibUserService } from '@herdia-common/lib-user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userService: LibUserService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = this.userService.user;
      if (user)
        return true;

      if (state.url != '/home')
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
      else
        this.router.navigate(['login']);
      return false;
  }
}
