import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HerdiaDatatableGlobalConfiguration, LibHerdiaDatatableComponent, ServerSideResponse } from "@herdia-common/lib-herdia-datatable";
import { LibDynamicComponentsEventBusService } from "@herdia-common/lib-dynamic-component-event-bus";
import { ColumnSetting } from "@herdia-common/lib-column-selector";
import { Observable } from 'rxjs';
import { LibFileDepositService, GetDatatableSpecificInfosRequest, IFileDeposit } from '@herdia-common/lib-file-deposit';

@Component({
  selector: 'app-preview-filedeposit-modal',
  templateUrl: './preview-filedeposit-modal.component.html',
  styleUrls: ['./preview-filedeposit-modal.component.scss']
})
export class PreviewFiledepositModalComponent implements OnInit {
  @Input() fileDepositRef: IFileDeposit = {
    Id: null,
    Label: "",
    Enable: true,
    EnableString: "yes",
    Type: 0,
    Tag: "",
    Filter: "",
    Auth: 0,
    Login: "",
    Password: "",
    Host: "",
    Port: 0,
    RootPath: "",
    Capabilities: [0]
  };

  variablesUsedToGetFiles: { key: string, value: string }[] = [];

  //datatable
  @ViewChild('herdiaDatatable') herdiaDatatable!: LibHerdiaDatatableComponent;
  eventBusService: LibDynamicComponentsEventBusService = new LibDynamicComponentsEventBusService();
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 5,
    autoWidth: false,
    fixedLeftColumns: 0,
    fixedRightColumns: 0
  };
  columnSettings: ColumnSetting[] = [
    {
      name: "FilePath",
      label: "Nom du fichier",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "IdHAFilesFromFileDeposit",
      label: "Id de la table",
      type: "number",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    }
  ]

  constructor(public activeModal: NgbActiveModal, public fileDeposit: LibFileDepositService) { }

  ngOnInit(): void {
    switch (this.fileDepositRef.Type) {
      case 0: {
        //docuware
        let dataFilter = this.fileDepositRef.Filter;
        const regex = /['"](.*?)['"]/g;
        const matches = dataFilter.match(regex);

        if (matches) {
          let words = matches.map(match => match.replace(/["']/g, ''));
          words = Array.from(new Set(words));
          words.forEach((word: string) => {
            this.variablesUsedToGetFiles.push({ key: word, value: '' });
          });
        }
        break;
      }
      case 1: {
        //fileSystem
        let dataFilter = this.fileDepositRef.Filter;
        const regex = /\$([^$]+)\$/g;
        const matches = dataFilter.match(regex);

        if (matches) {
          let words = matches.map(match => match.slice(1, -1));
          words = Array.from(new Set(words));
          words.forEach((word: string) => {
            this.variablesUsedToGetFiles.push({ key: word, value: '' });
          });
        }
        break;
      }
      default: {
        console.log("default from switch filedepositref type id");
        break;
      }
    }

    this.fileDeposit.FillFileInformationByType(this.fileDepositRef.Type).subscribe((response: any) => {
      if (response != null) {
        console.log(response);
      }
      else {
        console.log(response);
      }
    });
  }

  onValidate(): void {
    console.log(this.variablesUsedToGetFiles);
    this.herdiaDatatable.reload();
  }

  getData = (dataTablesParameters: any): Observable<ServerSideResponse> => {
    let requestParam: GetDatatableSpecificInfosRequest = { requestDatatable: dataTablesParameters, type: this.fileDepositRef.Type, variablesFilter: this.variablesUsedToGetFiles }
    return this.fileDeposit.GetDatatableSpecificInformationByType(requestParam);
  }

}
