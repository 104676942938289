import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { HaPageCard, HaPageRowVM } from '../../shared/models/interfaces';
import { CardService } from '../../shared/service/card.service';
import { LibDynamicComponentsEventBusService } from "@herdia-common/lib-dynamic-component-event-bus";
import { LibDynamicComponentService } from "@herdia-common/lib-dynamic-components";
import { AppService } from 'src/app/shared/service/app.service';
import { ModuleLoader } from 'src/app/shared/static/ModuleLoader';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { CardUrls } from '../../shared/service/api.service';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { ICardConfig } from '@herdia-common/lib-interface-card';

@Component({
  selector: 'app-card-box',
  templateUrl: './card-box.component.html',
  styleUrls: ['./card-box.component.scss'],
  providers: [LibDynamicComponentsEventBusService]
})
export class CardBoxComponent implements OnInit {
  @Input() cardId: number = 0;
  @Input() card!: HaPageCard;
  @Input() rowAvailableSpace: number = 0;
  @Input() isDynamic: boolean = true;
  @Input() row!: HaPageRowVM;

  @Output() onCardChange = new EventEmitter();

  editMode = false;
  cardConfigured = false;
  collapsedCard = false;
  maximizedCard = false;
  iconCard: IconName = "" as IconName;

  cardInterface: ICardConfig = {
      allowFullScreen: true, allowMobileView: true, hasButton: true, hasFooter: true, hasHeader: true, isConfigurable: true, minWidth: 1, cardCustomClasses: "", configTreeNode: []
  };


  constructor(private dynamicComponentService: LibDynamicComponentService, private cardSvc: CardService, public appSvc: AppService, public eventBusService: LibDynamicComponentsEventBusService, private apiSvc: LibHttpClientApiService) {
    cardSvc.closeSaveConfigSubject.subscribe(() => {
      this.onCardChange.next(true);
    });
  }

  ngOnInit(): void {
    this.dynamicComponentService.getCardConfigInterface(this.card.CardTypeLabel, this.getModuleLoader(this.card.Package)).then((result: ICardConfig) => {
      this.cardInterface = result;
      !this.cardInterface.isConfigurable ? this.cardConfigured = true : this.cardConfigured = false;
      this.cardConfigured = this.isDynamic == false || (this.card.Configuration !== undefined && Object.keys(this.card.Configuration).length > 1);
    });

    this.appSvc.editModeSub.subscribe(mode => {
      this.editMode = mode;
    });

    //get the icon string from the config of the card
    if(!this.card ||
       !this.card.Configuration ||
        !this.card.Configuration['icon'] ||
        typeof this.card.Configuration['icon'] === 'undefined' ||
        typeof this.card.Configuration['icon'] === undefined) {
      this.iconCard = "check-circle";
    }
    else {
      this.iconCard = this.card.Configuration['icon'];
    }
  }

  getModuleLoader(applicationName: any) {
    return ModuleLoader.getModuleLoader(applicationName);
  }

  editCardConfig() {
    this.cardSvc.openConfigModal(this.card, this.rowAvailableSpace);
  }

  //used to activate the maximized-card and collapsed-card classes of the card
  fullscreenCard() {
    if (this.maximizedCard == false) {
      this.maximizedCard = true;
    }
    else {
      this.maximizedCard = false;
    }
  }

  moveCardToRight() {
    this.row.HAPageCards.forEach((e, index) => {
      let nextCard = this.row.HAPageCards[index + 1];
      if (nextCard != undefined && e.Id == this.card.Id) {
        let currentCardOrder = this.row.HAPageCards[index].Order;
        this.row.HAPageCards[index].Order = nextCard.Order;
        this.row.HAPageCards[index + 1].Order = currentCardOrder;
      }
    })
    return this.apiSvc.put<HaPageRowVM, HaPageCard[]>(CardUrls.UPDATE_ORDER_CARDS, this.row)?.subscribe(data => {
      data.forEach(e => this.onCardChange.emit(e));
    });
  }

  moveCardToLeft() {
    this.row.HAPageCards.forEach((e, index) => {
      let previousCard = this.row.HAPageCards[index - 1];
      if (previousCard != undefined && e.Id == this.card.Id) {
        let currentCardOrder = this.row.HAPageCards[index].Order;
        this.row.HAPageCards[index].Order = previousCard.Order;
        this.row.HAPageCards[index - 1].Order = currentCardOrder;
      }
    })
    return this.apiSvc.put<HaPageRowVM, HaPageCard[]>(CardUrls.UPDATE_ORDER_CARDS, this.row)?.subscribe(data => {
      data.forEach(e => this.onCardChange.emit(e));
    });
  }
}
