<div class="col-md-12">
  <div class="card card-primary haCardBox container-toggle">
    <div class="card-header">
      <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp; <lib-translate [key]="'uploader-manager-list'"></lib-translate></h3>
      <div class="align-items-baseline card-tools d-flex">
        <button type="button" class="btn btn-tool" (click)="exportZip()">
          <fa-icon icon="download"></fa-icon>
        </button>
        <lib-herdia-datatable-column-selector-button [eventBusService]="eventBusService" [columnSettings]="cardCRUDUploadConfig.DataSource.Entity.ColumnsSettings"></lib-herdia-datatable-column-selector-button>
        <button type="button" class="btn btn-tool" #p="ngbPopover" placement="bottom-right" [popoverTitle]="popAddTitle" [ngbPopover]="popAddContent" [autoClose]="false">
          <fa-icon icon="plus"></fa-icon>
        </button>
        <ng-template #popAddTitle><lib-translate [key]="'lbl-add-a-file'"></lib-translate></ng-template>
        <ng-template #popAddContent>
          <div class="d-flex flex-column">
            <label><lib-translate [key]="'upload-a-file'"></lib-translate></label>
            <input type="file"
                   class="btn btn-primary btn-s ml-1"
                   accept=".zip"
                   (change)="onFileSelect($event.target)">
          </div>
        </ng-template>
      </div>
    </div>
    <div class="card-body">
      <div class="mt-3">
        <lib-card-generic-crud
          [config]="cardCRUDUploadConfig" [eventBusService]="eventBusService">
        </lib-card-generic-crud>
      </div>
    </div>
    <div class="card-footer">
      <lib-datatable-pagination [eventBusService]="eventBusService"></lib-datatable-pagination>
    </div>
  </div>
</div>


