import { Injectable } from '@angular/core';
import { IAddDBConnectionResponse } from '../models/interfaces';
import { Observable } from 'rxjs';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { DBConnectionURLs } from '../models/enums';
import { IAddDbConnection } from './../models/interfaces';
import {ServerSideResponse} from "@herdia-common/lib-herdia-datatable";
@Injectable({
  providedIn: 'root'
})
export class DBConnectionService {

  constructor(private apiSvc: LibHttpClientApiService) {

  }

  read(request: any): Observable<ServerSideResponse> {
    return this.apiSvc.post<any, ServerSideResponse>(DBConnectionURLs.READ, request);
  }

  add(newConnection: IAddDbConnection): Observable<IAddDBConnectionResponse> | null {
    return this.apiSvc.post<IAddDbConnection, IAddDBConnectionResponse>(DBConnectionURLs.ADD, newConnection);
  }

  delete(connectionId: number): Observable<number> | null {
    return this.apiSvc.delete(DBConnectionURLs.DELETE, { dbConnectionId: connectionId });
  }
}
