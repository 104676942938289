import { Component, ElementRef, OnInit } from '@angular/core';
import { HaPage, HaPageRow, HaPageRowVM, HaPageVM } from '../shared/models/interfaces';
import { RowUrls } from '../shared/service/api.service';
import { AppService } from '../shared/service/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { LibUserService } from '@herdia-common/lib-user';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DragulaOptions, DragulaService } from 'ng2-dragula';
import { PageService } from '../shared/service/page.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  haPageVM: HaPageVM = { Id: 0 };
  pageId = 1;
  editMode = false;
  arrowBtnIndex: number[] = [];

  constructor(private elem: ElementRef, private userService: LibUserService, private apiSvc: LibHttpClientApiService, private appSvc: AppService, private route: ActivatedRoute, private router: Router, private pageService: PageService) {
    router.events.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => this.loadPage());
    this.userService.setUserEvent.subscribe((u: any) => {
      this.loadPage();
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  loadPage(): void {
    if (this.userService.user) {
      this.pageId = Number(this.route.snapshot.queryParamMap.get('id'));
      if (this.pageId == 0) {
        this.pageId = 1;
      }
      this.pageService.getPage(this.pageId).subscribe(result => {
        this.haPageVM.HAPageRows = result.HAPageRows;
        this.haPageVM.Id = result.Id;
      });
      this.getRows();
      this.appSvc.editModeSub.subscribe(mode => {
        this.editMode = mode;
      });
    }
  }

  getRows() {
    this.apiSvc.get<HaPageRowVM[]>(RowUrls.GET_ROWS + "/" + this.pageId).subscribe(r => {
      this.haPageVM.HAPageRows = r.sort((a, b) => a.Order - b.Order);
    });
  }

  addRow(pageId: number | undefined) {
    const body = { pageId: pageId };
    return this.apiSvc.post<any, HaPageRowVM[]>(RowUrls.ADD_ROW, body)?.subscribe(r => {
      this.haPageVM.HAPageRows = r.sort((a, b) => a.Order - b.Order);
    });
  }

  update(e: HaPageRow) {
    this.haPageVM.HAPageRows = this.haPageVM.HAPageRows?.filter(row => row.Id !== e.Id);
  }

  //used to apply the active class on the element (the active class is used for the animation of the btn)
  GetClassAnimBtn(index: number){
    for(var i = 0; i < this.arrowBtnIndex.length; i++)
    {
      if(this.arrowBtnIndex[i] == index){
        return 'active';
      }
    }
    return '';
  }

  //used for add the index to the array arrowBtnIndex which is uses to add class + call function GrowUp_or_down_cards
  animBtn(index: number, event:any){
    for(var i = 0; i < this.arrowBtnIndex.length; i++)
    {
        if(this.arrowBtnIndex[i] == index){
          delete this.arrowBtnIndex[i]
          //call function to grow up all cards of the row next
          this.GrowUp_or_down_cards('remove',index)
          return;
         }
    }
    this.arrowBtnIndex.push(index);
    //call function to grow down all cards of the row next
    this.GrowUp_or_down_cards('add',index)
  }

  //used to make the animation of the card reducer
  GrowUp_or_down_cards(action:string,index:number){
    if(action == "add"){
      const id_row = '#row_' + index;
      const row = this.elem.nativeElement.querySelectorAll(id_row);
      const cards = row[0].children[0].children;
      row[0].querySelectorAll(".haCardBox").forEach((e: any) => e.classList.add('collapsed-card'));

    }
    else if(action == "remove")
    {
      const id_row = '#row_' + index;
      const row = this.elem.nativeElement.querySelectorAll(id_row);
      const cards = row[0].children[0].children;
      row[0].querySelectorAll(".haCardBox").forEach((e: any) => e.classList.remove('collapsed-card'));
    }
  };
}
