import { ToastService } from './shared/service/toast-notification.service';
import { Component, OnInit } from '@angular/core';
import { HaPage, PageManagement, RegistrationResponse, Role } from './shared/models/interfaces';
import { AccountService } from './shared/service/account.service';
import { CachingService } from './shared/service/caching.service';
import { PageUrls } from './shared/service/api.service';
import { PageService } from './shared/service/page.service';
import { CategoryService } from './shared/service/category.service';
import { AppService } from './shared/service/app.service';
import { NavigationEnd, Router } from '@angular/router';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import { ModuleLoader } from './shared/static/ModuleLoader';
import { SignalrService } from './shared/service/signalr.service';
import { LibUserService } from '@herdia-common/lib-user';
import {Title} from "@angular/platform-browser";
import { RoleService } from './shared/service/role.service';
import { PropertyDefinition } from "@herdia-common/lib-card-generic-crud";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  user: RegistrationResponse | null = null;
  isLoggedIn = false;
  isCachingLoading = false;
  pageMana: PageManagement = { Categories: [], Pages: [] };
  sideBarCollpsed = false;
  editMode = false;
  hasRightPanel = false;
  applicationName = "";
  applicationIcon = new Image();
  applicationBackgroundLogin = new Image();
  userRoles: Role[] = [];
  PagesRoles!: any[];
  applicationRightPanelPackageName!: string;
  applicationUserProperties!: PropertyDefinition[];

  constructor(private appSvc: AppService,
              private router: Router,
              private accountSvc: AccountService,
              private cachingSvc: CachingService,
              private apiSvc: LibHttpClientApiService,
              private pageSvc: PageService,
              private categorySrv: CategoryService,
              private translateService: LibHerdiaAppTranslateService,
              private signalRService: SignalrService,
              private toastService: ToastService,
              public userService: LibUserService,
              private titleService: Title,
              private roleSvc: RoleService
  ) {
    if (!localStorage.getItem("userLocale")) {
      translateService.setDefaultLang(navigator.language);
      translateService.use(navigator.language);
    }
    else {
      translateService.setDefaultLang(localStorage.getItem("userLocale") as string);
      translateService.use(localStorage.getItem("userLocale") as string);
    }
    this.apiSvc.updateSwaggerDefinition();
    this.accountSvc.userSub.subscribe(u => {
      this.user = u;
      this.isLoggedIn = this.user !== null;
      if (this.isLoggedIn) {
        this.getCategories();
        this.userService.user = this.user as object;
      }
    });
    this.cachingSvc.isLoadingSubject.subscribe(d => { this.isCachingLoading = d });
    if (this.isLoggedIn) {
      this.pageSvc.newPageSubject.subscribe(p => this.getCategories());
      this.pageSvc.dropPageSubject.subscribe(p => this.getCategories());
      this.pageSvc.duplicatePageSubject.subscribe(p => this.getCategories());
      this.pageSvc.editPageSubject.subscribe(p => this.getCategories());
      this.categorySrv.newCategorySubject.subscribe((n) => {
        this.getCategories();
      });
      this.signalRService.startConnection();
      this.signalRService.addToastNotificationListener();
    }
    this.appSvc.editModeSub.subscribe(mode => this.editMode = mode);
    this.appSvc.hasRightPanel.subscribe(rp => this.hasRightPanel = rp);
    this.appSvc.applicationUserProperties.subscribe(properties => this.applicationUserProperties = properties);
    this.appSvc.applicationName.subscribe(name => {
      this.applicationName = name;
      this.titleService.setTitle(name);
    });
    this.appSvc.applicationIconBase64.subscribe(iconBase64 => this.applicationIcon = appSvc.base64ToImage(iconBase64));
    this.appSvc.applicationBackgroundBase64.subscribe(backgroundBase64 => this.applicationBackgroundLogin = appSvc.base64ToImage(backgroundBase64));
    this.appSvc.applicationRightPanelPackageName.subscribe(rightPanelPackageName => {
      this.applicationRightPanelPackageName = rightPanelPackageName
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((ev) => { /* reinitialize cardsubjcts when changing page*/
      if (ev instanceof NavigationEnd)
        this.appSvc.applicationEventBus.cardSubjects = {};
    });
    this.getUserRolesAndPagesRoles();
  }

  onLogout() {
    this.accountSvc.logout();
  }

  get showNavigations(): boolean {
    return this.isLoggedIn && !this.isCachingLoading;
  }

  getCategories() {
    this.apiSvc.get<PageManagement>(PageUrls.INDEX).subscribe(r => {
        this.pageMana.Categories = r.Categories;
        this.pageMana.Pages = r.Pages;
    });
    this.getUserRolesAndPagesRoles();
  }

  onAddPage(): void {
    this.pageSvc.openAddModal();
  }

  onAddCategory(): void {
    this.categorySrv.openAddModal();
  }

  getModuleLoader(applicationName: string) {
    return ModuleLoader.getModuleLoader(applicationName);
  }

  getUserRolesAndPagesRoles(): void {
    if (this.user) {
      this.userService.getAllRole(this.user?.Id).subscribe(response => {
        //get all roles of the user 
        this.userRoles = response;
      })
      this.roleSvc.GetAllPagesWithRoles().subscribe(response => {
        //get all roles for all pages
        this.PagesRoles = response;
      })
    }
  }

  checkIfUserCanSeePage(page: HaPage): boolean {
    //check if the user is 'super admin'
    if (this.user?.Email == 'admin@herdia.fr')
      return true;

    //get page id and user id
    let idPage = page.Id
    let idUser = this.user?.Id;
    if (idUser)
    {
      let relatedPage = this.PagesRoles.find(elem => elem.IdPage == idPage);

      //compare the 2 arrays to see if at least one id exists in the other array 
      if (this.userRoles && relatedPage) {
        for (let rolePage of relatedPage.Roles) {
          for (let roleUser of this.userRoles) {
            if (rolePage.Id == roleUser.Id) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  onExportPage(): void {
    this.pageSvc.openExportModal();
  }
}
