import { LibDynamicMyherdiaComponentsModule } from "@herdia-myherdia/lib-dynamic-myherdia-components";
import { LibDynamicComponentService } from "@herdia-common/lib-dynamic-components";
import { ICardConfig } from "@herdia-common/lib-interface-card";

export class ModuleLoader {
  static getModuleLoader(applicationName: any) {
    switch (applicationName) {
      case "myHerdia":
        return () => import("@herdia-myherdia/lib-dynamic-myherdia-components").then(m => m.LibDynamicMyherdiaComponentsModule);
      default:
        return () => import("@herdia-common/lib-dynamic-components").then(m => m.LibDynamicComponentsModule);
    }
  }

  static getAvailableConfigSelectors(dynamicComponentService: LibDynamicComponentService, pkg: string, cardTypeLabel: string): Promise<string[]> {
    let result: string[] = [];
    return dynamicComponentService.getAvailableConfigSelectors(() => {
      switch (pkg) {
        case "myHerdia":
          return import("@herdia-myherdia/lib-dynamic-myherdia-components").then(m => m.LibDynamicMyherdiaComponentsModule)
        default:
          return import("@herdia-common/lib-dynamic-components").then(m => m.LibDynamicComponentsModule)
      }
    }, cardTypeLabel).then((list) => {
      result = list;
      dynamicComponentService.getAvailableConfigSelectors(() => import("@herdia-myherdia/lib-dynamic-myherdia-components").then(m => m.LibDynamicMyherdiaComponentsModule), cardTypeLabel).then((list) => {
        list.forEach(c => {
          if (result.indexOf(c) === -1)
            result.push(c);
        });
      });
    }).then(m => { return result; });
  }

  static getAvailableCardSelectors(dynamicComponentService: LibDynamicComponentService): Promise<{ package: string, selector: string, minWidth: number, disabled: boolean }[]> {
    let result: { package: string, selector: string, minWidth: number, disabled: boolean }[] = [];
    let selectors: string[] = [];
    return dynamicComponentService.getAvailableSelectors(() => import("@herdia-common/lib-dynamic-components").then(m => m.LibDynamicComponentsModule)).then((commonSelectors: string[]) => {
      commonSelectors.forEach((cs) => {
        if (cs.indexOf("lib-card-") !== -1) {
          // call of the function getCardConfigInterface to obtain an ICardConfig which will contain the information of my card,
          // here thanks to ICardConfig we will be able to return the minimum size of the card it is useful for us to configure the card 
          let cardConfig: ICardConfig;
          dynamicComponentService.getCardConfigInterface(cs, this.getModuleLoader('common')).then((res: ICardConfig) => {
            cardConfig = res;
            result.push({ package: "common", selector: cs, minWidth: cardConfig.minWidth, disabled: false });
            selectors.push(cs);
          })
        }
      });
    }).then((list) => {
      dynamicComponentService.getAvailableSelectors(() => import("@herdia-myherdia/lib-dynamic-myherdia-components").then(m => m.LibDynamicMyherdiaComponentsModule)).then((alhambraSelectors: string[]) => {
        alhambraSelectors.forEach((cs) => {
          if (selectors.indexOf(cs) == -1 && cs.indexOf("lib-card-") !== -1) {
            // same as above
            let cardConfig: ICardConfig;
            dynamicComponentService.getCardConfigInterface(cs, this.getModuleLoader('myHerdia')).then((res: ICardConfig) => {
              cardConfig = res;
              result.push({ package: "myHerdia", selector: cs, minWidth: cardConfig.minWidth, disabled: false });
            });
          }
          if (cs.indexOf("lib-card-") !== -1) {
            selectors.push(cs);
          }
        });
      })
    }).then(m => { return result; });
  }
  static getApplicationModule() {
    return LibDynamicMyherdiaComponentsModule;
  }
}


