<div [ngClass]="{'wrapper': showNavigations, 'login-box-wrapper': !showNavigations}" [ngStyle]="{'background-image': 'url(' + applicationBackgroundLogin.src + ')'}">

  <app-header *ngIf="showNavigations"></app-header>

  <aside class="main-sidebar sidebar-dark-primary elevation-4" *ngIf="showNavigations">
    <a class="brand-link">
      <img [src]="applicationIcon.src" class="brand-image img-circle elevation-3">
      <span class="brand-text font-weight-bold">{{applicationName}}</span>
    </a>
    <div class="sidebar">

      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu">
          <li class="nav-item has-treeview">
            <a class="nav-link" style="padding:0">
              <div class="d-inline-block col-md-12" style="max-width: 100%; padding:0" ngbDropdown #myDrop="ngbDropdown">
                <button type="button"
                        class="btn btn-outline-primary me-2 col-md-12"
                        id="dropdownManual"
                        (focus)="myDrop.open()">

                  <div class="contentBtn">
                    <i class="nav-icon img-circle elevation-3 user-icon col-md-3 koukouye">AA</i>
                    <p class="user-name"
                     ngbDropdownAnchor>
                      {{userService.userName}}
                    </p>
                  </div>

                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual" class="col-md-12 w-auto">
                  <button ngbDropdownItem>Profil</button>
                  <button (click)="onLogout()" ngbDropdownItem>Deconnexion</button>
                </div>
              </div>
            </a>
          </li>

          <li class="separator"></li>

          <li class="nav-item">
            <div class="form-inline">
              <div class="input-group" data-widget="sidebar-search">
                <input class="form-control form-control-sidebar" type="search" placeholder="{{'right-menu.lbl-search' | translate}}" aria-label="Search">
                <div class="input-group-append">
                  <button class="btn btn-sidebar">
                    <fa-icon icon="search"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="nav-item nav-accordion">
            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
              <ngb-panel *ngFor="let categorie of pageMana.Categories"><!-- categorie.Icon -->
                <ng-template ngbPanelTitle>
                  <span  class="text-truncate"><fa-icon [icon]="['fas', categorie.Icon]" class="nav-icon"></fa-icon>&nbsp;{{categorie.Label}}</span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <ng-container *ngFor="let page of pageMana.Pages"><!-- page.Icon -->
                    <a *ngIf="page.HAPageCategoryId == categorie.Id && checkIfUserCanSeePage(page)" [routerLink]="['/home']" [queryParams]="{ id: page.Id}" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                      <fa-icon [icon]="['fas', page.Icon]" class="nav-icon"></fa-icon><p class="d-inline">&nbsp;{{page.Title}}</p>
                    </a>
                  </ng-container>
                </ng-template>
              </ngb-panel>
              <ngb-panel>
                <ng-template ngbPanelTitle>
                  <span class="text-truncate"><fa-icon icon="cog" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-setting-category'"></lib-translate></span>
                </ng-template>
                <ng-template ngbPanelContent>
                  <a routerLink="/user" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="users" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-user-manager'"></lib-translate>
                  </a>
                  <a routerLink="/role" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="user-tag" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-role-manager'"></lib-translate>
                  </a>
                  <a routerLink="/content-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="file-alt" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-context-manager'"></lib-translate>
                  </a>
                  <a routerLink="/card-theme-designer" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="palette" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-theme-designer'"></lib-translate>
                  </a>
                  <a routerLink="/translate-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="language" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-translate-manager'"></lib-translate>
                  </a>
                  <a routerLink="/task-scheduler-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="calendar-check" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-menu-task-scheduler-manager'"></lib-translate>
                  </a>
                  <a routerLink="/uploader-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="upload" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-uploader-manager'"></lib-translate>
                  </a>
                  <a routerLink="/email-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="envelope" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-email-manager'"></lib-translate>
                  </a>
                  <a routerLink="/dbconnection-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="database" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'app-dbconnection-manager'"></lib-translate>
                  </a>
                  <a routerLink="/user-email-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="envelope" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'user-email-manager'"></lib-translate>
                  </a>
                  <a routerLink="/filedeposit-manager" routerLinkActive="active" ariaCurrentWhenActive="page" class="nav-link">
                    <fa-icon icon="file-pen" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'file-deposit-manager'"></lib-translate>
                  </a>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
        </div>
        <br />
        <div *ngIf="editMode" class="nav-accordion accordion">
          <div class="accordion-item">
            <button class="accordion-button fake-accordion">
              <fa-icon icon="plus" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'add-category-menu'" (click)="onAddCategory()"></lib-translate>
            </button>
          </div>
          <div class="accordion-item">
            <button class="accordion-button fake-accordion">
              <fa-icon icon="plus" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'add-page-menu'" (click)="onAddPage()"></lib-translate>
            </button>
          </div>
          <div class="accordion-item">
            <button class="accordion-button fake-accordion">
              <fa-icon icon="upload" class="nav-icon"></fa-icon>&nbsp;<lib-translate [key]="'export-page-menu'" (click)="onExportPage()"></lib-translate>
            </button>
          </div>
        </div>
      </nav>
    </div>
  </aside>


  <aside *ngIf="hasRightPanel && isLoggedIn" class="control-sidebar control-sidebar-dark" style="padding: 0.5rem; width: 25rem;">
      <app-dynamic-selector *ngIf="applicationRightPanelPackageName" #rightPanel
                            [componentSelector]="'lib-panel-right'"
                            [moduleLoaderFunction]="getModuleLoader(applicationRightPanelPackageName)"></app-dynamic-selector>
  </aside>


  <div [ngClass]="{'content-wrapper px-3 py-3': showNavigations}">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="showNavigations"></app-footer>

</div>
<toast-notification aria-live="polite" aria-atomic="true"></toast-notification>
