import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IDDatatableEventType } from 'src/app/shared/models/interfaces';

@Component({
  selector: 'datatable-row-action',
  templateUrl: './datatable-row-action.component.html',
  styleUrls: ['./datatable-row-action.component.scss']
})
export class DatatableUserMailRowActionComponent implements OnInit, OnDestroy {

  constructor() { }

  @Output()
  emitter = new Subject<IDDatatableEventType>();

  @Input()
  data = {};

  ngOnInit(): void {
  }

  onEdit(): void {
    this.emitter.next({
      cmd: "edit",
      data: this.data,
    });
  }

  onDelete(): void {
    this.emitter.next({
      cmd: "delete",
      data: this.data,
    });
  }

  sendTestMail(): void {
    this.emitter.next({
      cmd: "sendTestMail",
      data: this.data,
    });
  }

  ngOnDestroy() {
    this.emitter.unsubscribe();
  }

}
