<div class="col-md-12">
  <div class="card card-primary haCardBox container-toggle">
    <div class="card-header">
      <h3 class="card-title" title="Gestionnaire de tâche"><fa-icon icon="check-circle"></fa-icon>&nbsp; <lib-translate [key]="'card-title-task-scheduler'"></lib-translate></h3>
      <div class="align-items-baseline card-tools d-flex">
        <button type="button" class="btn btn-tool" (click)="add()"><fa-icon icon="plus" class="ng-fa-icon"></fa-icon></button>
      </div>
    </div>

    <div class="card-body">
      <div>
        <ul ngbNav #nav="ngbNav" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink><lib-translate [key]="'nav-title-list-task'"></lib-translate></a>
            <ng-template ngbNavContent>
              <div class="mt-3">
                <lib-herdia-datatable #herdiaDatatable
                                      [columnSettings]="columnSettings"
                                      [herdiaDTConfig]="herdiaDTConfig"
                                      [eventBusService]="eventBusService"
                                      [dataGetter]="getData"></lib-herdia-datatable>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink><lib-translate [key]="'nav-title-log'"></lib-translate></a>
            <ng-template ngbNavContent>
              <p>
                LOG
              </p>
            </ng-template>
          </li>
        </ul>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
    <div class="card-footer">
      <lib-datatable-pagination [eventBusService]="eventBusService"></lib-datatable-pagination>
    </div>
  </div>
</div>

