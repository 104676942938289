import {Component, OnInit, ViewChild} from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import {
  HerdiaDatatableGlobalConfiguration,
  LibHerdiaDatatableComponent, ServerSideRequest,
  ServerSideResponse
} from "@herdia-common/lib-herdia-datatable";
import {LibDynamicComponentsEventBusService} from "@herdia-common/lib-dynamic-component-event-bus";
import {ColumnSetting} from "@herdia-common/lib-column-selector";
import {Observable, Subject} from "rxjs";
import {LibHerdiaAppTranslateCustomLoader} from "../shared/service/translate.service";
@Component({
  selector: 'app-tranlate-manager',
  templateUrl: './translate-manager.component.html',
  styleUrls: ['./translate-manager.component.scss']
})
export class TranslateManagerComponent implements OnInit {
  @ViewChild('herdiaDatatable') herdiaDatatable!:LibHerdiaDatatableComponent;
  eventBusService: LibDynamicComponentsEventBusService = new LibDynamicComponentsEventBusService();
  searchEntitySubject: Subject<string> = new Subject<string>();
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 0,
    fixedRightColumns: 1
  };

  searchValue = "";

  onSearch(): void {
    this.eventBusService.addSubject("SearchEntity", this.searchEntitySubject);
    this.searchEntitySubject.next(this.searchValue);
  }

  Edit = (data: any): void => {
    console.log("Edit clicked");
  }
  Remove = (data: any): void => {
    console.log("Remove clicked");
  }

  columnSettings: ColumnSetting[] = [
    {
      name: "Code",
      label: "Code",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "EnLabel",
      label: "Anglais",
      type: "string",
      visible: true,
      horizontalAlign: 'left',
      displaySum: false,
      actionColumnDef: null,
      nbDecimal: 0,
      nature: "normal",
      width: 200
    },
    {
      name: "FrLabel",
      label: "Français",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "DeLabel",
      label: "Allemand",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "Context",
      label: "Contexte",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "edit",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.Edit
          },
          {
            type: "button",
            icon: "trash",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.Remove
          }
        ]
      },
      displaySum: false,
      nbDecimal : 0,
      nature: "normal",
      width: 100,
      horizontalAlign: "center"
    }
  ];
  constructor(private translatePipe: TranslatePipe, private translateService: LibHerdiaAppTranslateCustomLoader) { }

  ngOnInit(): void {

  }



  getData = (dataTablesParameters: ServerSideRequest) : Observable<ServerSideResponse> => {
    return this.translateService.getTranslationTable(dataTablesParameters);
  }
}
