import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LibFileDepositService, IFileDeposit, TypeFileDeposit, AuthFileDeposit, CapabilitiesFileDeposit } from '@herdia-common/lib-file-deposit';

@Component({
  selector: 'app-edit-filedeposit-modal',
  templateUrl: './edit-filedeposit-modal.component.html',
  styleUrls: ['./edit-filedeposit-modal.component.scss']
})
export class EditFiledepositModalComponent implements OnInit {
  @Input() fileDepositToEdit: IFileDeposit = {
    Id: null,
    Label: "",
    Enable: true,
    EnableString: "yes",
    Type: 0,
    Tag: "",
    Filter: "",
    Auth: 0,
    Login: "",
    Password: "",
    Host: "",
    Port: 0,
    RootPath: "",
    Capabilities: [0]
  };
  @Output() public fileDepositEdited = new EventEmitter<void>();

  typeFileDeposit: TypeFileDeposit[] = [
    { id: 0, Label: "Docuware" },
    { id: 1, Label: "FileSystem" }
  ];
  authTypeFileDeposit: AuthFileDeposit[] = [
    { id: 0, Label: "Basic" },
    { id: 1, Label: "Ldap" },
    { id: 2, Label: "GoogleAuth" },
    { id: 3, Label: "ApiKey" }
  ];
  CapabilitiesFileDeposit: CapabilitiesFileDeposit[] = [
    { id: 0, Label: "None" },
    { id: 1, Label: "Player" },
  ];

  constructor(public activeModal: NgbActiveModal, private FileDepositService: LibFileDepositService) { }

  ngOnInit(): void {
  }

  onValidate() {
    // call FileDepositService method for edit
    this.FileDepositService.EditFileDeposit(this.fileDepositToEdit).subscribe((r: any) => {
      if (r.success == true) {
        this.fileDepositEdited.emit();
        this.activeModal.close();
      }
      else {
        console.log("error update : " + r.message);
      }
    });
  }
}
