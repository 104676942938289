import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { LibSchedulerService } from '@herdia-common/lib-scheduler-service';
import { Job, Trigger } from '@herdia-common/lib-scheduler-service';
import { TranslatePipe } from '@ngx-translate/core';
import { AppService } from '../../shared/service/app.service';
import { CreateOrUpdateJobRequest } from '@herdia-common/lib-scheduler-service';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import { LibCronEditorComponent } from '@herdia-common/lib-cron-editor';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-add-edit-task-scheduler',
  templateUrl: './add-edit-task-scheduler.component.html',
  styleUrls: ['./add-edit-task-scheduler.component.scss']
})
export class AddEditTaskSchedulerComponent implements OnInit {

  @ViewChild('actions') actions!: LibCronEditorComponent;

  @Input() title: string = "title";
  @Input() currentItem: Job = new Job();
  @Input() currentTrigger: Trigger = new Trigger();
  @Output() itemUpdated = new EventEmitter<Job>();
  @Output() itemAdded = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<Job>();

  editMode = false;
  addEditModalCronExpression: string = "";
  addEditModalJobName: string = "";
  addEditModalDescription: string = "";
  addEditModalCreator: string = "";

  listClassJobs: { FullName: string; Name: string }[]= [];
  JobsSelected: string = '';

  addRequest: CreateOrUpdateJobRequest = {
    JobName: "",
    CronExpression: "* * * ? * * *",
    Description: "",
    Creator: "",
    JobClass: ""
  }

  display: string = "none";
  showDelete: boolean = true;


  context: string = "update";

  constructor(private taskSchedulerService: LibSchedulerService, translatePipe: TranslatePipe, appService: AppService, private translateService: LibHerdiaAppTranslateService, public activeModal: NgbActiveModal) {
    appService.editModeSub.subscribe(e => { this.editMode = e; });
    this.addEditModalCronExpression = translatePipe.transform("add-edit-modal-job-cron-expression");
    this.addEditModalJobName = translatePipe.transform("add-edit-modal-job-name");
    this.addEditModalDescription = translatePipe.transform("add-edit-modal-job-description");
    this.addEditModalCreator = translatePipe.transform("add-edit-modal-job-creator");
  }

  ngOnInit(): void {
    //get classes for jobs
    this.taskSchedulerService.getAllClassJobs().subscribe((res : any) => {
      this.listClassJobs = res;
    });
  }

  onAdd(): void {
    this.context = "add";
    this.showDelete = false;
    this.openModal();
  }

  onEdit(item: Job): void {
    this.currentItem = item;
    this.currentTrigger = item.Trigger;
    this.context = "update";
    this.showDelete = true;
    this.openModal();
  }

  onValidateAdd(): void {
    this.addRequest.CronExpression = this.actions.finalCronExpression;
    this.itemAdded.emit(this.addRequest);
    this.activeModal.close();
  }

  onValidateUpdate(): void {
    this.itemUpdated.emit(this.currentItem);
    this.activeModal.close();
  }

  onDelete(): void {
    this.itemDeleted.emit(this.currentItem);
    this.closeModal();
  }

  openModal(): void {
    this.display = 'block';
  }

  closeModal() {
    this.display = 'none';
  }

}
