<div class="modal-header">
  <h4 class="modal-title">{{this.title}}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group" *ngIf="context == 'update'">
    <label for="jobName">{{'add-edit-modal-job-name'|translate}}</label>
    <input type="text" class="form-control" name="jobName" placeholder="{{addEditModalJobName}}" [(ngModel)]="currentItem.Name" disabled>
  </div>
  <div class="form-group" *ngIf="context == 'add'">
    <label for="jobName">{{'add-edit-modal-job-name'|translate}}</label>
    <input type="text" class="form-control" name="jobName" placeholder="{{addEditModalJobName}}" [(ngModel)]="addRequest.JobName">
  </div>

  <lib-cron-editor #actions
                   [currentTrigger]="currentTrigger"
                   [context]="context"
                   [displayMinute]="true"
                   [displayHours]="true">
  </lib-cron-editor>

  <div class="form-group" *ngIf="context == 'add'">
    <label for="cronExpression">{{'add-edit-modal-job-list-classes'|translate}}</label>
    <ng-select [items]="listClassJobs" bindLabel="Name" bindValue="FullName" [(ngModel)]="addRequest.JobClass"></ng-select>
  </div>
  <div class="form-group" *ngIf="context == 'add'">
    <label for="description">{{'add-edit-modal-job-description'|translate}}</label>
    <input type="text" class="form-control" name="description" placeholder="{{addEditModalDescription}}" [(ngModel)]="addRequest.Description">
  </div>
  <div class="form-group" *ngIf="context == 'add'">
    <label for="creator">{{'add-edit-modal-job-creator'|translate}}</label>
    <input type="text" class="form-control" name="creator" placeholder="{{addEditModalCreator}}" [(ngModel)]="addRequest.Creator">
  </div>
</div>
<div class="modal-footer">
  <button *ngIf="context == 'update'" type="button" class="btn btn-primary" (click)="onValidateUpdate()">Valider</button>
  <button *ngIf="context == 'add'" type="button" class="btn btn-primary" (click)="onValidateAdd()">Valider</button>
  <button type="button" class="btn btn-secondary" (click)="closeModal()">Fermer</button>
</div>
