
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"><lib-translate [key]="'add-card-modal-title'"></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label><lib-translate [key]="'lbl-icon'"></lib-translate></label>
    <lib-icon-picker (iconSelectedOutput)="onIconPickerSelect($event)"></lib-icon-picker>
    <label *ngIf="emptyIcon" class="text-danger"><lib-translate [key]="'lbl-icon-missing'"></lib-translate></label>
  </div>
  <div class="mb-3">
    <label for="cardTitle"><lib-translate [key]="'add-card-title'"></lib-translate></label>
    <div class="input-group">
      <input [(ngModel)]="cardBase.cardTitle" class="form-control" name="cardTitle" />
    </div>
  </div>
  <div class="mb-3">
    <label for="cardType"><lib-translate [key]="'add-card-type'"></lib-translate></label>
    <div class="input-group">
      <ng-select [items]="availableDynamicCardSelector" [(ngModel)]="selectedCard" groupBy="package" bindLabel="selector" class="col-md-12">
      </ng-select>
    </div>
  </div>
  <div class="mb-3">
    <label for="cardWidth"><lib-translate [key]="'add-card-width'"></lib-translate>:&nbsp;{{cardBase.cardWidth}}</label>
    <div class="input-group">
      <input type="range" name="cardWidth" id="cardWidth" min="{{selectedCard.minWidth}}" max="{{maxWidth}}" value="{{maxWidth}}" step="1" [(ngModel)]="cardBase.cardWidth">
    </div>
  </div>
  <div class="mb-3">
    <div class="alert alert-warning" role="alert">
      <lib-translate [key]="'texte-information-minimum-width-card'"></lib-translate> {{selectedCard.selector}} <lib-translate [key]="'texte-information-minimum-width-card-part2'"></lib-translate> {{selectedCard.minWidth}} <br />
      <lib-translate [key]="'texte-information-remaining-width-line'"></lib-translate> {{maxWidth}}
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark mr-auto" (click)="onImport()"><lib-translate [key]="'app-btn-import'"></lib-translate></button>
  <button type="button" class="btn btn-outline-dark" (click)="saveCard()"><lib-translate [key]="'add-card-modal-save'"></lib-translate></button>
</div>
