
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"><lib-translate [key]="'add-card-modal-title'"></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="mb-3">
    <label for="cardType"><lib-translate [key]="'add-predefined-card-label'"></lib-translate></label>
    <div class="input-group">
      <ng-select [items]="availablePredefinedCards" [(ngModel)]="cardBase.predefinedCardId" bindLabel="Title" bindValue="Id" class="col-md-12">
      </ng-select>
    </div>
  </div>
  <div class="mb-3">
    <label for="cardWidth"><lib-translate [key]="'add-card-width'"></lib-translate>:&nbsp;{{cardBase.predefinedCardWidth}}</label>
    <div class="input-group">
      <input type="range" name="cardWidth" id="cardWidth" min="1" max="{{maxWidth}}" value="{{maxWidth}}" step="1" [(ngModel)]="cardBase.predefinedCardWidth">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="saveCard()"><lib-translate [key]="'add-card-modal-save'"></lib-translate></button>
</div>
