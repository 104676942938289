<nav class="main-header navbar navbar-expand">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a (click)="sideBarAnim()" class="nav-link" data-widget="pushmenu">
        <fa-icon icon="bars"></fa-icon>
      </a>
    </li>
  </ul>
  <ul class="navbar-nav ml-auto">
    <!-- Right navbar links -->
    <!-- Navbar Search -->
    <li class="nav-item">
      <a class="nav-link" data-widget="navbar-search" href="#" role="button">
        <fa-icon icon="search"></fa-icon>
      </a>
      <div class="navbar-search-block">
        <form class="form-inline">
          <div class="input-group input-group-sm">
            <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
            <div class="input-group-append">
              <button class="btn btn-navbar" type="submit">
                <fa-icon icon="search"></fa-icon>
              </button>
              <button class="btn btn-navbar" type="button" data-widget="navbar-search">
                <fa-icon icon="times"></fa-icon>
              </button>
            </div>
          </div>
        </form>
      </div>
    </li>

    <!-- Messages Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <fa-icon icon="comments"></fa-icon>
        <span class="badge badge-info navbar-badge badge-message">0</span>
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <div class="messageHolder">
        </div>
        <div class="messageWriter d-flex">
          <input id="messageInput" type="text" class="form-control form-control-sm" />
          <button class="btn btn-tool" onclick="MessageHandler.SendToAll($('#messageInput').val());"><i class="fas fa-paper-plane"></i></button>
        </div>
      </div>
    </li>
    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown">
      <a id="notificationBell" class="nav-link" data-toggle="dropdown" data-widget="notificationDiv" role="button" popoverClass="notificationPopover" placement="bottom" [popoverTitle]="popTitle" [ngbPopover]="popContent">
        <fa-icon icon="bell"></fa-icon>
        <span class="badge badge-info navbar-badge">{{this.persistentNotificationService.Notifications.length}}</span>
      </a>
      <ng-template #popTitle><b>{{this.persistentNotificationService.Notifications.length}}&nbsp;notifications</b></ng-template>
      <ng-template #popContent>
        <persistent-notification *ngFor="let notif of this.persistentNotificationService.Notifications" [notification]="notif"></persistent-notification>
        <div class="dropdown-divider"></div>
      </ng-template>
    </li>

    <li class="nav-item">
      <a id="edit" class="nav-link" data-widget="" (click)="setEditMode()" role="button" [ngClass]="{'active-icon': editMode}">
        <fa-icon icon="edit"></fa-icon>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" data-widget="fullscreen" role="button">
        <fa-icon icon="expand-arrows-alt"></fa-icon>
      </a>
    </li>
    <li *ngIf="hasRightPanel" class="nav-item">
      <a (click)="sideBarFilterAnim()" class="nav-link showFilter" data-widget="control-sidebar" data-slide="true" role="button">
        <fa-icon icon="th-large"></fa-icon>
      </a>
    </li>
  </ul>
</nav>

