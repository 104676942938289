import { Injectable } from '@angular/core';
import { map, Observable, Subject, tap } from 'rxjs';
import { HaPageCard } from '../models/interfaces';
import { CardUrls } from './api.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { CardConfigComponent } from '../../cards/card-config/card-config.component';
import { SaveCardConfigComponent } from '../../cards/card-config/save-card-config/save-card-config.component';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { ExportCardConfigComponent } from '../../cards/card-config/export-card-config/export-card-config.component';
import { AddCardConfigurationModalComponent } from '../../home/add-card/add-card-modal/add-card-configuration-modal/add-card-configuration-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  cardSubjects: { [k: string]: Subject<any> } = {};
  subjectAdded: Subject<string> = new Subject<string>();

  editCardModalId = 'editCardModal';
  saveCardConfigModalId = 'saveCardConfigModal';

  initEditCardSubject = new Subject<HaPageCard>();
  initSaveConfigSubject = new Subject<HaPageCard>();
  closeSaveConfigSubject = new Subject<void>();
  closeImportConfigSubject = new Subject<void>();

  cardUpdatedSubject = new Subject<number>();
  cardDeletedSubject = new Subject<number>();
  currentModal!: NgbModalRef;

  constructor(private apiSvc: LibHttpClientApiService, private modalService: NgbModal) { }

  addSubject(name: string, subject: Subject<any>) {
    if (this.cardSubjects[name] === undefined) {
      this.cardSubjects[name] = subject;
      this.subjectAdded.next(name);
    }
  }

  openAndInitEditModal(card: HaPageCard) {
    this.openEditModal();
    this.initEditCardSubject.next(card);
  }

  openEditModal() {
    // jQuery(`#${this.editCardModalId}`).modal('show');
  }

  closeEditModal() {
    this.currentModal.close();
    this.closeSaveConfigSubject.next();
  }

  openConfigModal(card: HaPageCard, rowAvaibleSpace: number) {
    this.currentModal = this.modalService.open(CardConfigComponent, { size: 'xl' });
    this.currentModal.componentInstance.card = card;
    this.currentModal.componentInstance.maxWidth = card.Width + rowAvaibleSpace;
  }

  openAndInitSaveConfigModal(card: HaPageCard) {
    const modalRef = this.modalService.open(SaveCardConfigComponent);
    modalRef.componentInstance.cardInput = card;
  }

  openAndInitExportConfig(card: HaPageCard) {
    const modalRef = this.modalService.open(ExportCardConfigComponent);
    modalRef.componentInstance.cardInput = card;
  }

  openAndInitImportConfig(pageRowId : number) {
    const modalRef = this.modalService.open(AddCardConfigurationModalComponent);
    modalRef.componentInstance.pageRowId = pageRowId;
    modalRef.componentInstance.cardImported.subscribe(() => { this.closeImportConfigSubject.next(); });
  }

  getCard(cardId: number): Observable<HaPageCard | undefined> | undefined {
    if (cardId === 0) {
      console.error(`getCard(): CardId cannot be 0`);
      return undefined;
    }

    return this.apiSvc.get<{ [key: string]: string }>(`${CardUrls.GET}/${cardId}`).pipe(map((c: any) => {
      const card = c as HaPageCard;
      if (card) {
        return card;
      } else {
        console.error(`getCard(): Card with id ${cardId} not found ...`);
        return undefined;
      }
    }));
  }

    updateCard(card: HaPageCard) {
    return this.apiSvc.put<HaPageCard, any>(CardUrls.UPDATE, card)?.pipe(tap(c => {
      if (!c) {
        console.error(`Update of card with id ${card.Id} failed`);
      }
      else {
        this.currentModal.close();
        this.closeSaveConfigSubject.next();
      }
    }));
  }

  deleteCard(cardId: number) {
    return this.apiSvc.delete1<any>(`${CardUrls.DELETE}/${cardId}`)?.pipe(tap(r => {
      if (!r)
        console.error(`Deletion of card with id ${cardId} failed`);
      else {
        this.currentModal.close();
        this.closeSaveConfigSubject.next();
      }
    }));
  }

  getMaxWidth(cardId: number, cardRowId: number) {
    return this.apiSvc.get<{ maxWidth: number }>(CardUrls.GET_MAX_WIDTH, { cardId: cardId, cardRowId: cardRowId });
  }

  saveConfig(param: { title: string, config: any, cardTypeLabel: string, packageLabel: string }) {
    return this.apiSvc.post<any, { msg: string }>(CardUrls.SAVE_CONFIG, param);
  }

  exportConfig(param: { title: string, cardTitle: string | undefined, cardTypeLabel: string, config: any, width: string, requestUserEmail: string }) {
    return this.apiSvc.post<any, { msg: string }>(CardUrls.EXPORT_CONFIG, param);
  }

  importConfig(formData: FormData) {
    return this.apiSvc.post<any, { msg: string }>(CardUrls.IMPORT_CONFIG, formData);
  }
}
