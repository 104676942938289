import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Job, JobAssociatedTrigger, LibSchedulerService, Trigger } from '@herdia-common/lib-scheduler-service';
import { CreateOrUpdateJobRequest } from '@herdia-common/lib-scheduler-service';
import { Router } from '@angular/router';
import { AddEditTaskSchedulerComponent } from './add-edit-task-scheduler/add-edit-task-scheduler.component';
import {
  HerdiaDatatableGlobalConfiguration,
  LibHerdiaDatatableComponent,
  ServerSideResponse
} from "@herdia-common/lib-herdia-datatable";
import {ColumnSetting} from "@herdia-common/lib-column-selector";
import {LibDynamicComponentsEventBusService} from "@herdia-common/lib-dynamic-component-event-bus";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LibConfirmBoxService } from "@herdia-common/lib-confirm-box"
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-task-scheduler-manager',
  templateUrl: './task-scheduler-manager.component.html',
  styleUrls: ['./task-scheduler-manager.component.scss']
})
export class TaskSchedulerManagerComponent implements OnInit {
  @ViewChild('actions') actions!: AddEditTaskSchedulerComponent;
  @ViewChild('herdiaDatatable') herdiaDatatable!:LibHerdiaDatatableComponent;

  deleteTitleModal: string = "";
  deleteMsgModal: string = "";
  deleteValidateModal: string = "";
  deleteCancelModal: string = "";
  jobToDelete!: Job;

  eventBusService: LibDynamicComponentsEventBusService = new LibDynamicComponentsEventBusService();
  job = new Job();
  trigger = new Trigger();
  jobAndTrigger = new JobAssociatedTrigger();
  jobs: Job[] = [];

  deleteTask = (item: any): void => {
    this.jobToDelete = item as Job;

    this.confirmBoxSvc.open({
      title: this.deleteTitleModal,
      msg: this.deleteMsgModal,
      okBtnLbl: this.deleteValidateModal,
      koBtnLbl: this.deleteCancelModal,
    });
  }

  editTask = (job: Job) => {

    const modalRef = this.modalService.open(AddEditTaskSchedulerComponent, { size: 'lg' });
    modalRef.componentInstance.title = "Edition";
    modalRef.componentInstance.context = "update";
    modalRef.componentInstance.onEdit(job);
    modalRef.componentInstance.itemUpdated.subscribe((newItem: any) => {
      this.onItemUpdated(newItem);
    });
  }

  runTask = (job: Job) => {
    this.taskSchedulerService.runJob(job.Name)?.subscribe();
  }

  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: true,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 1,
    fixedRightColumns: 1
  };
  columnSettings: ColumnSetting[] = [
    {
      name: "Name",
      label: "Name",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 150,
      actionColumnDef: null
    },
    {
      name: "Group",
      label: "Group",
      type: "string",
      visible: true,
      horizontalAlign: 'left',
      displaySum: false,
      actionColumnDef: null,
      nbDecimal: 0,
      nature: "normal",
      width: 70
    },
    {
      name: "Description",
      label: "Description",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 200,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "JobType",
      label: "JobType",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    // {
    //   name: "JobDataMap",
    //   label: "JobDataMap",
    //   type: "string",
    //   visible: true,
    //   horizontalAlign: "left",
    //   width: 100,
    //   actionColumnDef: null,
    //   nature: "normal",
    //   nbDecimal: 0,
    //   displaySum: false
    // },
    {
      name: "PreviousFireTime",
      label: "PreviousFireTime",
      type: "DateTime?",
      visible: true,
      horizontalAlign: "center",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "NextFireTime",
      label: "NextFireTime",
      type: "DateTime?",
      visible: true,
      horizontalAlign: "center",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    // {
    //   name: "trigger",
    //   label: "trigger",
    //   type: "string",
    //   visible: true,
    //   horizontalAlign: "center",
    //   width: 100,
    //   actionColumnDef: null,
    //   nature: "normal",
    //   nbDecimal: 0,
    //   displaySum: false
    // },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "file-lines",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool danger',
            onclick: this.runTask
          },
          {
            type: "button",
            icon: "play",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool danger',
            onclick: this.runTask
          },
          {
            type: "button",
            icon: "pen-to-square",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.editTask
          },
          {
            type: "button",
            icon: "trash",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.deleteTask
          }
        ]
      },
      displaySum: false,
      nbDecimal : 0,
      nature: "normal",
      width: 100,
      horizontalAlign: "center"
    }
  ];
  timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  constructor(private taskSchedulerService: LibSchedulerService, private translatePipe: TranslatePipe, private router: Router, private modalService: NgbModal, public activeModal: NgbActiveModal, private confirmBoxSvc: LibConfirmBoxService) {
    this.deleteTitleModal = translatePipe.transform("delete-job-title-modal-card");
    this.deleteMsgModal = translatePipe.transform("delete-job-message-modal-card");
    this.deleteValidateModal = translatePipe.transform("delete-job-validate-button-modal-card");
    this.deleteCancelModal = translatePipe.transform("delete-job-cancel-button-modal-card");

    confirmBoxSvc.confirmationSub.subscribe((c: Boolean) => {
      if (c) {
        this.taskSchedulerService.deleteJob(this.jobToDelete.Name)!.subscribe((c) => {
          if (c) {
            this.herdiaDatatable.reload();
            this.activeModal.close();
          }
        });
      }
    });
  }

  getData = (dataTablesParameters: any) : Observable<ServerSideResponse> => {
    return this.taskSchedulerService.getAll({
      dataTableRequest: dataTablesParameters,
      clientTimeZone: this.timeZone
    });
  }

  ngOnInit(): void {
  }



  add() {
    let modalRef = this.modalService.open(AddEditTaskSchedulerComponent, { size: 'lg'});
    modalRef.componentInstance.title = "Ajout";
    modalRef.componentInstance.context = "add";
    modalRef.componentInstance.itemAdded.subscribe((newItem: any) => {
      this.onItemAdded(newItem);
    });
  }



  onItemUpdated(item: any): void {
    const job = item as Job;
    const updateRequest = {
      JobName: job.Name,
      CronExpression: job.Trigger.CronExpressionString,
      Description: job.Description,
      Creator: job.JobDataMap.Creator,
      JobClass: job.JobClass
    } as CreateOrUpdateJobRequest;
    this.taskSchedulerService.createOrUpdateJob(updateRequest)!.subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }

  onItemAdded(addRequest: CreateOrUpdateJobRequest): void {
    this.taskSchedulerService.createOrUpdateJob(addRequest)!.subscribe(() => {
      this.herdiaDatatable.reload();
    });
  }
}
