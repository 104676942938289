import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AddCardRequest } from 'src/app/shared/models/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CardUrls } from 'src/app/shared/service/api.service';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { FormControl, FormGroup } from '@angular/forms';
import { CardService } from '../../../shared/service/card.service';

@Component({
  selector: 'app-add-card-modal',
  templateUrl: './add-card-modal.component.html',
  styleUrls: ['./add-card-modal.component.scss']
})
export class AddCardModalComponent implements OnInit {

  cardBase: AddCardRequest = {
    cardTitle: "",
    cardType: "",
    cardWidth: 1,
    pageRowId: 0,
    package: "common",
    icon: ""
  };
  @Input() maxWidth = 12;
  @Output() cardAdded = new EventEmitter();
  availableDynamicCardSelector!: [{ package: string, selector: string, minWidth: number, disabled: boolean}];
  selectedCard: { package: string, selector: string, minWidth: number, disabled: boolean } = { package: '', selector: '', minWidth: 1, disabled: false};
  emptyIcon: boolean = false;

  myFormGroup: FormGroup;
  iconCss = new FormControl();

  constructor(private apiService: LibHttpClientApiService, private cardService: CardService, public activeModal: NgbActiveModal) {
    this.myFormGroup = new FormGroup({iconCss: this.iconCss});
  }

  ngOnInit(): void {
    this.availableDynamicCardSelector
      .filter((card) => card.minWidth > this.maxWidth)
      .forEach((card) => card.disabled = true);
  }

  saveCard(): void {
    if (this.cardBase.icon != "" && !this.emptyIcon) {
      this.cardBase.cardType = this.selectedCard.selector;
      this.cardBase.package = this.selectedCard.package;

      this.apiService.post(CardUrls.ADD, this.cardBase)?.subscribe(() => {
        this.cardAdded.emit();
        this.activeModal.close();
      })
    }
    else {
      this.emptyIcon = true;
    }
  }

  onIconPickerSelect(icon: string): void {
    this.emptyIcon = false;
    this.iconCss.setValue(icon);
    this.cardBase.icon = icon;
  }

  onImport() {
    this.cardService.openAndInitImportConfig(this.cardBase.pageRowId);
    this.cardService.closeImportConfigSubject.subscribe(() => {
      this.cardAdded.emit();
      this.activeModal.close();
    })
  }
}
