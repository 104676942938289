import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import { AccountService } from '../shared/service/account.service';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  //lib translate
  selectedLocal: string = navigator.language;

  token: string = '';
  user_email: string = '';
  ShowValidEmailConfirmation: boolean = false;
  ShowNoValidEmailConfirmation: boolean = false;

  constructor(private route: ActivatedRoute,private activatedRoute: ActivatedRoute, private account_svc: AccountService, private translateService: LibHerdiaAppTranslateService) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.token = params['token'];
        this.user_email = params['mail'];
      });
    this.account_svc.emailConfirmation(this.user_email, encodeURIComponent(this.token)).subscribe((res : any) => {
      if(res == true){
        this.ShowValidEmailConfirmation = true;
      }
      else{
        this.ShowNoValidEmailConfirmation = true;
      } 
    })
  }
  
  //function to change the translation of the page
  onLocalChanged(local: string) {
    //this.translationSvc.updateTranslations(local);
    localStorage.setItem("userLocale", local);
    this.translateService.use(local);
  }

}
