import { Observable } from "rxjs"
import { DBConnectionType, ToastType } from "./enums"
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { ICardConfig } from "@herdia-common/lib-interface-card";
import { ServerSideRequest } from "@herdia-common/lib-herdia-datatable";
export interface Login {
  Email: string,
  Password: string
}

export interface GoogleLogin {
  Email: string,
  AuthToken: string,
  FirstName: string,
  LastName: string
}

export interface TokenRequest {
  Token: string,
  RefreshToken: string,
}

export interface AuthRes {
  Token: string,
  RefreshToken: string,
  Success: boolean,
  Errors: string[]
}

export interface RegistrationResponse extends AuthRes {
  Id: string,
  FirstName: string,
  LastName: string,
  Email: string,
  UserName: string,
  Roles: string[],
  DateFormat: string,
  Currency: string,
  AreaUnit: string
}

export interface LocalStorageTrans {
  Locale: string,
  Data?: { [key: string]: string }
}

export interface LocalStorageTranslation {
  Hash: string,
  Datas: Map<string, string>
}

export interface PageManagement {
  Categories: HaPageCategory[],
  Pages: HaPage[];
}

export interface HaPageCategory {
  Id: number,
  Label: string,
  Description: string,
  Icon: IconName;
  EditMode: boolean,
  HAPages: HaPage[]
}


export interface HaPage {
  Id: number
  Title: string,
  Description: string,
  Icon: IconName,
  EditMode?: boolean,
  HAPageCategoryId?: number,
  HAPageCategory?: HaPageCategory,
  HAPageRows?: HaPageRow[],
}

export interface HaPageVM {
  Id: number,
  HAPageRows?: HaPageRowVM[],
  Roles?: [{Id: string}]
}

export interface HaPageRowVM {
  Id: number,
  Order: number,
  HAPageId: number,
  HAPageCards: HaPageCard[]
}

export interface HaPageRow {
  Id: number,
  Order: number,
  HAPageId: number,
  HAPage: HaPage,
  HAPageCards: HaPageCard[]
}



export interface HaPageCard extends ICardConfig {
  Id: number,
  Title?: string,
  Width: number,
  MaxWidth: number,
  AllowCollapse?: boolean,
  CardTypeLabel: string,
  CardConfiguration?: string,
  EditMode?: boolean,
  ViewName?: string,
  Configuration: { [key: string]: any },
  HaPageRowId?: number,
  Order: number,
  HaPageRow?: HaPageRow,
  Package: string
}


export interface ApiInfo {
  Datas?: (paramObject: any) => Observable<any> | null,
  ReportDatas?: (datatableParams: any, paramObject: any) => Observable<any> | null,
  ColumnUniqueValues?: (datatableParams: any, paramObject: any) => Observable<any> | null,
  DatatableColumns?: (languageCode: string) => Observable<any> | null,
  Headers?: () => Observable<any>,
  ParamTypes?: { [key: string]: string },
  OutputTypes?: { [key: string]: string }
}


export interface AddPageRequest {
  categoryId: number,
  pageTitle: string,
  pageDescription: string,
  pageIcon:  string,
  idRoles: string[]
}

export interface EditPageRequest {
  pageId?: number,
  categoryId: number,
  pageTitle: string,
  pageDescription: string,
  pageIcon: string,
}

export interface AddCardRequest {
  cardTitle: string,
  cardType: string,
  cardWidth: number,
  pageRowId: number,
  package: string,
  icon: string
}

export interface AddPredefinedCardRequest {
  predefinedCardWidth: number,
  pageRowId: number,
  predefinedCardId: number
}

export interface HAPageCardDefinedConfiguration {
  id: number,
  title: string,
  cardConfiguration: string,
  cardTypeLabel: string,
  packageLabel: string,
}

export interface RemovePageRequest {
  id: number
}

export interface DuplicatePageRequest {
  id: number
}

export interface HACategory {
  id: number,
  label: string,
  description: string,
  icon: string
}

export interface AddCategoryRequest {
  label: string,
  description: string,
  icon: string
}

export interface UpdateCategoryRequest {
  id: number;
  label: string,
  description: string,
  icon: string
}

export interface DeleteCategoryRequest {
  id: number;
}

export interface UpdateCardNewConfig {
  Name: string, Value: string
}

export interface UpdateCardRes {
  success: boolean,
  reloadPage: boolean,
  reloadCard: boolean
}

export interface ToastMessage {
  Type: ToastType,
  Recipient: string,
  TitleCode: string,
  ContentCode: string,
  ContentDownloadURL: string,
  ContentDownloadsFilename: string,
  Closable: boolean,
  Persistent: boolean
}

export interface PersistentNotification {
  id: string,
  date: Date,
  message: ToastMessage
}

export interface Role {
  Id: string,
  Name: string
}

export interface Collapsable {
  Collapsed: boolean
}

export interface CategoryRoleActions extends Collapsable {
  CategoryId: number,
  Name: string,
  Actions: { View: boolean, Add: boolean, Edit: boolean, RoleId: string }[],
  Pages: PageRoleActions[]
}

export interface PageRoleActions extends Collapsable {
  PageId: number,
  Name: string,
  Actions: { View: boolean, Add: boolean, Edit: boolean, Remove: boolean, RoleId: string }[],
  Cards: CardRoleActions[]
}

export interface CardRoleActions {
  CardId: number,
  Name: string,
  Actions: { View: boolean, Add: boolean, Edit: boolean, Remove: boolean, RoleId: string }[]
}
///
export interface UserGroup { Id: number, Name: string }

export interface CategoryVisibility extends Collapsable {
  Name: string,
  Visibilities: { View: boolean, Add: boolean, Edit: boolean, UserGroupId: number }[],
  PageVisibilities: PageVisibility[]
}

export interface PageVisibility extends Collapsable {
  Name: string,
  Visibilities: { View: boolean, Add: boolean, Edit: boolean, Remove: boolean, UserGroupId: number }[],
  CardVisibilities: CardVisibility[]
}

export interface CardVisibility {
  Name: string,
  Visibilities: { View: boolean, Add: boolean, Edit: boolean, Remove: boolean, UserGroupId: number }[]
}

export interface DeleteJobResponse {
  Success: boolean;
}

export interface CreateOrUpdateJobRequest {
  CronExpression: string,
  JobName: string,
  Description: string,
  Creator: string,
  JobClass: string
}

export interface UploadBackUpRequest {
  File: any;
}

export interface AddActionsMissingRequest {
  RoleId: string,
  ElementId: string,
  Type: string,
  Actions: { View: boolean, Add: boolean, Edit: boolean, Remove: boolean }
}

export interface ModifyRoleViewOnPageRequest {
  Action: boolean,
  RoleId: string,
  PageId: number
}
export interface InsertViewPageRoleRequest {
  RoleId: string,
  PageId: number
}

export interface ModifyRoleViewOnPageRequest {
  Action: boolean,
  RoleId: string,
  PageId: number
}
export interface InsertViewPageRoleRequest {
  RoleId: string,
  PageId: number
}

export interface HADbConnection {
  id: number,
  connectionType: DBConnectionType,
  name: string,
  connectionString: string,
  apiRoute: string,
  assemblyUploadDefinitionId: number,
  pdbUploadDefinitionId: number,
  sourcesUploadDefinitionId: number
}
export interface UpdateThemeRequest {
  Label: string,
  PrimaryValue: string,
  SecondaryValue: string,
  customFontSize: string,
  navbarValue: string,
  topNavbarValue: string
}

export interface ThemeVariables {
  PrimaryColor: string,
  SecondaryColor: string,
  CustomFontSize: string,
  LeftNavbarColor: string,
  TopNavbarColor: string
}

/// Application/User Email manager
export interface HAUpdateEmailTemplateRequest {
  TemplateId: number,
  TemplateName: string,
  TemplateNewContent: string
}
export interface HASendEmailForTestTemplateRequest {
  IdEmailTemplate: number;
}
///

/// User Email manager
export interface HAAddUserEmailTemplateRequest {
  NameEmailTemplate: string,
  ContentEmailTemplate: string
}

///

export interface IDDatatableEventType {
  cmd: string;
  data: any;
}

export interface IAddDbConnection {
  connectionType: number,
  name: string,
  connectionString: string,
  contextApiRoute: string
}

export interface IAddDBConnectionResponse {
  IsInError: boolean,
  State: number,
  Messages: string[]
}

export interface ExportPageRequest {
  pageId: number,
  fileTitle: string,
  requestUserEmail: string
}

export interface ExportPageResponse {
  Success: boolean,
  Message: string
}

export interface HATranslation {
  Id: number;
  Code: string;
  EnLabel: string;
  FrLabel: string;
  DeLabel: string;
  Context: string;
}


