import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LibUserService, User } from '@herdia-common/lib-user';
import {
  HerdiaDatatableGlobalConfiguration,
  LibHerdiaDatatableComponent,
  ServerSideResponse
} from "@herdia-common/lib-herdia-datatable";
import {ColumnSetting} from "@herdia-common/lib-column-selector";
import { Observable } from 'rxjs';
import {LibDynamicComponentsEventBusService} from "@herdia-common/lib-dynamic-component-event-bus";
import { LibConfirmBoxService } from "@herdia-common/lib-confirm-box"
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  @ViewChild('herdiaDatatable') herdiaDatatable!:LibHerdiaDatatableComponent;

  eventBusService: LibDynamicComponentsEventBusService = new LibDynamicComponentsEventBusService();
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 0,
    fixedRightColumns: 1
  };

  deleteTitleModal: string = "";
  deleteMsgModal: string = "";
  deleteValidateModal: string = "";
  deleteCancelModal: string = "";
  userToDelete!: User;

  onView = (user: User): void => {
    this.router.navigate(['user', 'profile', user.Id]);
  }
  onEdit = (user: User): void => {
    this.router.navigate(['user', 'addedit', user.Id]);
  }

  onDelete = (user: User): void => {
    this.userToDelete = user;
    this.confirmBoxSvc.open({
      title: this.deleteTitleModal,
      msg: this.deleteMsgModal + " " + user.FirstName + " " + user.LastName,
      okBtnLbl: this.deleteValidateModal,
      koBtnLbl: this.deleteCancelModal,
    });
  }

  columnSettings: ColumnSetting[] = [
    {
      name: "LastName",
      actionColumnDef: null,
      nature: "normal",
      width: 100,
      horizontalAlign: "left",
      nbDecimal: 0,
      displaySum: false,
      type: "string",
      visible: true,
      label: "LastName"
    },
    {
      name: "FirstName",
      actionColumnDef: null,
      nature: "normal",
      width: 100,
      horizontalAlign: "left",
      nbDecimal: 0,
      displaySum: false,
      type: "string",
      visible: true,
      label: "FirstName"
    },
    {
      name: "UserName",
      actionColumnDef: null,
      nature: "normal",
      width: 100,
      horizontalAlign: "left",
      nbDecimal: 0,
      displaySum: false,
      type: "string",
      visible: true,
      label: "UserName"
    },
    {
      name: "Phone",
      actionColumnDef: null,
      nature: "normal",
      width: 100,
      horizontalAlign: "left",
      nbDecimal: 0,
      displaySum: false,
      type: "string",
      visible: true,
      label: "Phone"
    },
    {
      name: "Actions",
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "eye",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onView
          },
          {
            type: "button",
            icon: "edit",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onEdit
          },
          {
            type: "button",
            icon: "trash",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.onDelete
          }
        ]
      },
      nature: "normal",
      width: 100,
      horizontalAlign: "center",
      nbDecimal: 0,
      displaySum: false,
      type: "custom",
      visible: true,
      label: "Actions"
    }
  ];

  users: User[] = [];

  colums: DataTables.ColumnSettings[] = [
    { title: 'LastName', data: 'LastName' },
    { title: 'FirstName', data: 'FirstName' },
    { title: 'UserName', data: 'UserName' },
    { title: 'Email', data: 'Email' },
    { title: 'Phone', data: 'Phone' }
  ];

  dtOptions: DataTables.Settings = {
    pageLength: 10,
    serverSide: true,
    processing: true,
    columns: this.colums,
    ajax: (params, callback) => {
      this.userSvc.getAll(params)?.subscribe((data: ServerSideResponse) => {
        if (data.data) {
          this.users = data.data?.map(d => d as User);
          callback({
            recordsTotal: data.recordsTotal,
            recordsFiltered: data.recordsFiltered,
            data: []
          });
        } else
          console.error('Server does\'nt return any user data');
      });
    }
  };

  constructor(private userSvc: LibUserService, private router: Router, private modal: NgbModal, private confirmBoxSvc: LibConfirmBoxService, private translatePipe: TranslatePipe, public activeModal: NgbActiveModal)
  {
    this.deleteTitleModal = translatePipe.transform("delete-user-title-modal-card");
    this.deleteMsgModal = translatePipe.transform("delete-user-message-modal-card") + " : ";
    this.deleteValidateModal = translatePipe.transform("delete-user-validate-button-modal-card");
    this.deleteCancelModal = translatePipe.transform("delete-user-cancel-button-modal-card");

    confirmBoxSvc.confirmationSub.subscribe((c: Boolean) => {
      if (c) {
        this.userSvc.delete(this.userToDelete.Id).subscribe((c) => {
          if (c) {
            this.herdiaDatatable.reload();
            this.activeModal.close();
          }
        });
      }
    });
  }

  getData = (dataTablesParameters: any) : Observable<ServerSideResponse> => {
    return this.userSvc.getAll(dataTablesParameters);
  }

  ngOnInit(): void {
  }

  add() {
    this.router.navigate(['user', 'addedit']);
  }

}
