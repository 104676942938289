<div class="modal-header">
  <h4 class="modal-title"><lib-translate [key]="'import-page-modal-title'"></lib-translate></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <div class="form-group">
    <label for="pageCategory"><lib-translate [key]="'import-page-modal-page-category'"></lib-translate></label>
    <ng-select [items]="availableCategories" name="pageCategory" [(ngModel)]="selectedCategory" bindValue="Id" bindLabel="Label"></ng-select>
  </div>
  <div class="form-group">
    <label for="pageImportConfigFile"><lib-translate [key]="'import-page-config-file-title'"></lib-translate></label>
    <input type="file" name="pageImportConfigFile" class="form-control" (change)="onFileSelected($event)">
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Fermer</button>
  <button type="button" class="btn btn-primary" (click)="onValidate()">Valider</button>
</div>
