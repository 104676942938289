import { Component, OnInit, TemplateRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { EmailTemplateService } from '../shared/service/application.email.template.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor/lib/config';
import { HASendEmailForTestTemplateRequest, HAUpdateEmailTemplateRequest, IDDatatableEventType } from '../shared/models/interfaces';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import {Observable, Subject } from 'rxjs';
import {
  HerdiaDatatableGlobalConfiguration,
  LibHerdiaDatatableComponent,
  ServerSideResponse
} from "@herdia-common/lib-herdia-datatable";
import {ColumnSetting} from "@herdia-common/lib-column-selector";
import {LibDynamicComponentsEventBusService} from "@herdia-common/lib-dynamic-component-event-bus";



@Component({
  selector: 'app-email-manager',
  templateUrl: './email-manager.component.html',
  styleUrls: ['./email-manager.component.scss']
})
export class EmailManagerComponent implements OnInit, OnDestroy{
  @ViewChild('modal') modal!: TemplateRef<any>;
  @ViewChild('herdiaDatatable') herdiaDatatable!:LibHerdiaDatatableComponent;

  eventBusService: LibDynamicComponentsEventBusService = new LibDynamicComponentsEventBusService();
  herdiaDTConfig: HerdiaDatatableGlobalConfiguration = {
    compactMode: false,
    cellBorder: true,
    hover: true,
    orderColumn: true,
    rowBorder: false,
    stripe: true,
    nowrap: false,
    defaultRowNumberOnPage: 10,
    autoWidth: false,
    fixedLeftColumns: 0,
    fixedRightColumns: 1
  };

  // used to edit the template
  EditEmailTemplate = (data: any): void => {
    this.OpenModalEditorEmailTemplate(data, this.modal);
  }
  //send mail for testing the template
  SendEmailForTestTemplate = (data: any): void => {
    const requestParam: HASendEmailForTestTemplateRequest = {
      IdEmailTemplate: data.Id
    }
    this.EmailTemplateService.SendEmailForTestTemplate(requestParam)?.subscribe((response: any) => {
      console.log(response);
    })
  }

  columnSettings: ColumnSetting[] = [
    {
      name: "FileName",
      label: "Nom de fichier",
      type: "string",
      visible: true,
      nbDecimal: 0,
      nature: "normal",
      horizontalAlign: 'left',
      displaySum: false,
      width: 200,
      actionColumnDef: null
    },
    {
      name: "DateUpload",
      label: "Date upload",
      type: "string",
      visible: true,
      horizontalAlign: 'left',
      displaySum: false,
      actionColumnDef: null,
      nbDecimal: 0,
      nature: "normal",
      width: 200
    },
    {
      name: "Nature",
      label: "Nature",
      type: "string",
      visible: true,
      horizontalAlign: "left",
      width: 100,
      actionColumnDef: null,
      nature: "normal",
      nbDecimal: 0,
      displaySum: false
    },
    {
      name: "Actions",
      label: "Actions",
      type: "custom",
      visible: true,
      actionColumnDef: {
        align: "center",
        buttons: [
          {
            type: "button",
            icon: "pen-to-square",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.EditEmailTemplate
          },
          {
            type: "button",
            icon: "envelope",
            text: "",
            class: 'btn btn-primary btn-sm btn-tool',
            onclick: this.SendEmailForTestTemplate
          }
        ]
      },
      displaySum: false,
      nbDecimal : 0,
      nature: "normal",
      width: 100,
      horizontalAlign: "center"
    }
  ];

  //htmlEditor
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '20rem',
    minHeight: '20rem',
    maxHeight: '20rem',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarHiddenButtons: [['insertVideo']],
    customClasses: [
      {
        name: "Main Color",
        class: "mainColor",
      },
      {
        name: "Secondary Color",
        class: "secondaryColor",
      },
    ]
  };

  modalRef!: NgbModalRef;
  htmlContent!: string;
  VariableEmailTemplate!: any;
  TemplateSelected: any;

  constructor(private EmailTemplateService: EmailTemplateService, private modalService: NgbModal, private translateService: LibHerdiaAppTranslateService) {

  }

  getData = (dataTablesParameters: any) : Observable<ServerSideResponse> => {
    return this.EmailTemplateService.GetAllEmailTemplate(dataTablesParameters);
  }

  ngOnInit(): void {
  }


  //open modal and get the content html of the current template selected (double click)
  OpenModalEditorEmailTemplate(data : any, modal: TemplateRef<any>) : void {
    this.TemplateSelected = data;
    this.EmailTemplateService.GetHtmlPartiel(data.Id).subscribe((response : any) => {
        this.htmlContent = response.Content;
    });
    this.modalRef = this.modalService.open(modal, { size: 'xl'});
  }

  //update email template + refresh datatable
  updateEmailTemplate(): void {
    const requestParam: HAUpdateEmailTemplateRequest = {
      TemplateId: this.TemplateSelected.Id,
      TemplateName: this.TemplateSelected.FileName,
      TemplateNewContent: this.htmlContent
    }
    this.EmailTemplateService.UpdateContentTemplate(requestParam)?.subscribe((response: any) => {
      if(response == true){
        this.herdiaDatatable.reload();
      }
      this.modalRef.close();
    })
  }

  ngOnDestroy(): void {
  }
}
