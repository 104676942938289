import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponentBase } from '../../../shared/models/baseClasses';
import { HaPageCard } from '../../../shared/models/interfaces';
import { CardService } from '../../../shared/service/card.service';

@Component({
  selector: 'app-save-card-config',
  templateUrl: './save-card-config.component.html',
  styleUrls: ['./save-card-config.component.scss']
})
export class SaveCardConfigComponent extends FormComponentBase implements OnInit {

  templateCardTitle = "";
  configTitlePlaceHolder = '';
  card?: HaPageCard;

  @Input() set cardInput(value: HaPageCard) {
    if (!value)
      return;

    this.card = value;

    this.submitted = false;
    if (this.card.Title === undefined)
      console.error('Titre de la carte est vide');
    else {
      this.form.value.configTitle = this.card.Title;
      this.configTitlePlaceHolder = `Configuration de ${this.card.Title}`;
    }
  }

  form = this.fb.group<{ configTitle: FormControl<string | null> }>({
    configTitle: this.fb.control('')
  })

  constructor(private cardSvc: CardService, private fb: FormBuilder, public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void { }

  save() {
    this.submitted = true;

    if (!this.form.valid)
      return;

    if (this.card && this.templateCardTitle !== "") {
      this.card.Configuration["HAPageCardId"] = this.card.Id //add the id reference for a predefined card to the config.
      const param = {
        title: this.templateCardTitle,
        config: JSON.stringify(this.card.Configuration),
        cardTypeLabel: this.card.CardTypeLabel,
        packageLabel: this.card.Package
      };
      this.cardSvc.saveConfig(param)?.subscribe(res => {
        if (res)
          this.activeModal.close();
        else
          console.error(`Erreur lors de la sauvegarde de la configuration de la carte ${this.form.get('configTitle')?.value}`);
      });
    } else
      console.error('La carte est vide');
  }

  cancel() {
    this.activeModal.dismiss();
  }

}
