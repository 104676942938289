import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import { Subscription } from 'rxjs';
import { FormComponentBase } from '../shared/models/baseClasses';
import { AccountService } from '../shared/service/account.service';
import { CachingService } from '../shared/service/caching.service';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login'
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { LibUserService } from '@herdia-common/lib-user';
import { AppService } from '../shared/service/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends FormComponentBase implements OnInit, OnDestroy {

  form: FormGroup;
  locals: string[] = [];
  selectedLocal: string = navigator.language;
  loginInvalide = false;
  socialUser!: SocialUser;
  isLoggedin?: boolean;
  isLoading = false;
  loadingProgress = 0;
  loadingLabel = 'Loading...';
  cacheLoadingSubscription: Subscription | undefined;
  applicationName = "";

  constructor(private fBuilder: FormBuilder,
    private router: Router,
    private accountSvc: AccountService,
    private route: ActivatedRoute,
    private cachingSvc: CachingService,
    private translateService: LibHerdiaAppTranslateService,
    private socialAuthService: SocialAuthService,
    private userService: LibUserService,
    private appSvc: AppService
  ) {
    super();
    this.form = this.fBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedin = user != null;
      if (this.isLoggedin == true) {
        this.accountSvc.googleAuthLogin(user.email, user.idToken, user.firstName, user.lastName)?.subscribe((r) => {
          if (r.Success) {
            this.router.navigate(['home']);
          }
          else if (!r.Success) {
            console.log("Login Error");
          }
        });
      }
      else if (this.isLoggedin == false) {
        this.router.navigate(['login']);
      }
    });
    this.appSvc.applicationName.subscribe(name => this.applicationName = name);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.cacheLoadingSubscription)
      this.cacheLoadingSubscription.unsubscribe();
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid)
      return;

    this.isLoading = true;

    this.accountSvc.
      login(this.form.get('username')?.value, this.form.get('password')?.value)?.subscribe(data => {
        if (data.Success) {
          this.loginInvalide = false;
          this.cacheLoadingSubscription = this.cachingSvc.loadCache().subscribe({
            next: status => {
              this.loadingProgress = status.progress;
              this.loadingLabel = status.label;
            },
            complete: () => {
              this.userService.language = this.selectedLocal;
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'home';
              this.router.navigate([returnUrl]);
            },
            error: error => { console.error(error) }
          });
        } else {
          this.loginInvalide = true;
          this.isLoading = false;
        }
      });
  }

  onGoogleLogout() {
    this.socialAuthService.signOut();
  }

  onLocalChanged(local: string) {
    //this.translationSvc.updateTranslations(local);
    localStorage.setItem("userLocale", local);
    this.translateService.use(local);
  }
}
