<div>
  <div class="modal-header">
    <h4 class="modal-title"><lib-translate [key]="'preview-filedeposit-modal-title'"></lib-translate></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
  </div>
  <div class="modal-body">
    <div *ngFor="let item of variablesUsedToGetFiles; let index = index">
      <div class="form-group">
        <label for="FileDepositFilter_{{item.key}}">{{item.key}}</label>
        <input type="text" class="form-control" name="FileDepositFilter_{{item.key}}" [(ngModel)]="variablesUsedToGetFiles[index].value">
      </div>
    </div>

    <div>
      <lib-herdia-datatable #herdiaDatatable
                            [columnSettings]="columnSettings"
                            [dataGetter]="getData"
                            [keepRowNumber]="true"
                            [herdiaDTConfig]="herdiaDTConfig"
                            [eventBusService]="eventBusService">
      </lib-herdia-datatable>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()"><lib-translate [key]="'close'"></lib-translate></button>
    <button type="button" class="btn btn-primary" (click)="onValidate()"><lib-translate [key]="'validate'"></lib-translate></button>
  </div>
</div>
