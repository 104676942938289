<div class="col-md-12">
  <div class="card card-primary haCardBox container-toggle">
    <div class="card-header">
      <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp; <lib-translate [key]="'card-title-file-deposit-manager'"></lib-translate></h3>
      <div class="align-items-baseline card-tools d-flex">
        <div>
          <button type="button" class="btn btn-tool" (click)="onAddFileDeposit()"><fa-icon icon="plus" class="ng-fa-icon"></fa-icon></button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="mt-3">
        <lib-herdia-datatable #herdiaDatatable
                              [columnSettings]="columnSettings"
                              [dataGetter]="getData"
                              [keepRowNumber]="true"
                              [herdiaDTConfig]="herdiaDTConfig"
                              [eventBusService]="eventBusService">
        </lib-herdia-datatable>
      </div>
    </div>
    <div class="card-footer">
      <lib-datatable-pagination [eventBusService]="eventBusService"></lib-datatable-pagination>
    </div>
  </div>
</div>


