export enum PropertyOption {
  IsReadOnly = 0,
  IsForeignKey = 1,
  IsKey = 2,
  IsNullable = 3
}

export enum ApplicationFeatures {
  RightPanel = 0
}

export enum ToastType
{
  Standard = 0,
  Success = 1,
  Danger =2
}

export enum NotificationURLs {
  ACKNOWLEDGE_NOTIFICATION = "api/Notification/AcknowledgeNotification"
}

export enum DBConnectionType {
  SqlServer = 0,
  MySQL = 1,
  PgSQL = 2
}

export enum DBConnectionURLs {
  READ = "api/DBConnection/ReadDBConnection",
  ADD = "api/DBConnection/AddDbConnection",
  DELETE = "api/DBConnection/DeleteDBConnection"
}

export enum HATranslationURLs {
  TABLE = "api/HATranslation/GetTranslationTable"
}
