import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormComponentBase } from '../shared/models/baseClasses';
import { PageService } from '../shared/service/page.service';
import { LibHerdiaAppTranslateService } from '@herdia-common/lib-herdia-app-translate';
import { ExportPageRequest, HaPage } from '../shared/models/interfaces';
import { LibUserService } from '@herdia-common/lib-user';


@Component({
  selector: 'app-export-page-config-modal',
  templateUrl: './export-page-config-modal.component.html',
  styleUrls: ['./export-page-config-modal.component.scss']
})
export class ExportPageConfigModalComponent extends FormComponentBase implements OnInit {

  templatePageTitle = "";
  configTitlePlaceHolder = '';
  pagesAvailable:HaPage[] = [];
  selectedPage: number = 0;

  constructor(private pageService: PageService, private translateService: LibHerdiaAppTranslateService, private userService: LibUserService, public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    this.configTitlePlaceHolder = this.translateService.get('exportTitlePlaceHolder')
    this.pageService.getPages().subscribe((res) => {
      if (res)
        this.pagesAvailable = res;
    });
  }

  export() {
    if (this.selectedPage != 0 && this.templatePageTitle !== "") {
      const param: ExportPageRequest = {
        pageId: this.selectedPage,
        fileTitle: this.templatePageTitle,
        requestUserEmail: this.userService.user!.Email
      };
      this.pageService.exportConfig(param)?.subscribe(res => {
        if (res.Success)
          this.activeModal.close();
        else
          console.error(res.Message);
      });
    } else
      console.error('Some fields are empty');
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
