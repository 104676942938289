import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LibUserService } from '@herdia-common/lib-user';
import { PageService } from '../service/page.service';
import { Role } from '../models/interfaces';

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  constructor(private router: Router, private userService: LibUserService, private pageService: PageService) { }
  //this guard exist for verify if one of roles of user can view the page 

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.userService.user;
    if (user) {
      //check if the user is 'super admin'
      if (user.Email == 'admin@herdia.fr')
        return true;

      //get page id and user id
      let idPage = parseInt(state.url.split('=')[1]);
      let idUser = user.Id;

      this.userService.getAllRole(idUser).subscribe(response => {
        //get all roles of the user 
        let userRoles:Role[] = response;

        this.pageService.getPage(idPage).subscribe(response => {
          //get all roles which is authorized to see this page 
          let pageRoles = response.Roles;

          //compare the 2 arrays to see if at least one id exists in the other array 
          if (userRoles && pageRoles) {
            for (let userRole of userRoles) {
              for (let pageRole of pageRoles) {
                if (userRole.Id == pageRole.Id) {
                  return true;
                }
              }
            }
          }
          //if not redirect to the page unauthorized and return false 
          this.router.navigate(['unauthorized-page']);
          return false;
        });
      });

    }
    




    return true;
  }
  
}
