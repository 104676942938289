import { Injectable } from '@angular/core';
import { AppEmailManager } from './api.service';
import { LibHttpClientApiService } from '@herdia-common/lib-http-client-api';
import { HASendEmailForTestTemplateRequest, HAUpdateEmailTemplateRequest } from '../models/interfaces';
import {ServerSideRequest, ServerSideResponse} from "@herdia-common/lib-herdia-datatable";

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  constructor(private apiSvc: LibHttpClientApiService) { }

  GetAllEmailTemplate(param: ServerSideRequest){
    return this.apiSvc.post<ServerSideRequest, ServerSideResponse>(AppEmailManager.GET_ALL_EMAIL,param);
  }

  GetHtmlPartiel(tempalteID:number){
    var url = AppEmailManager.GET_CONTENT_TEMPLATE+`${tempalteID}`;
    return this.apiSvc.get(url);
  }

  UpdateContentTemplate(param: HAUpdateEmailTemplateRequest){
    return this.apiSvc.put<HAUpdateEmailTemplateRequest, any>(AppEmailManager.UPDATE_CONTENT_TEMPLATE,param);
  }

  GetDescriptionVariablesTemplates() {
    return this.apiSvc.get(AppEmailManager.GET_DESCRIPTION_VARIABLES_TEMPLATES);
  }

  SendEmailForTestTemplate(param: HASendEmailForTestTemplateRequest) {
    return this.apiSvc.post<HASendEmailForTestTemplateRequest, any>(AppEmailManager.SEND_EMAIL_FOR_TEST,param);
  }
}
