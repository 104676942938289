<div class="col-md-12">
    <div class="card card-primary haCardBox container-toggle">
        <div class="card-header">
            <h3 class="card-title"><fa-icon icon="check-circle"></fa-icon>&nbsp; <lib-translate [key]="'card-title-user-email-manager'"></lib-translate></h3>
            <div class="align-items-baseline card-tools d-flex">
                <button type="button" class="btn btn-tool" (click)="OpenModalAddEmailTemplate()"><fa-icon icon="plus" class="ng-fa-icon"></fa-icon></button>
            </div>
        </div>

        <div class="card-body">
            <div class="mt-3">
              <lib-herdia-datatable #herdiaDatatable
                                    [columnSettings]="columnSettings"
                                    [dataGetter]="getData"
                                    [eventBusService]="eventBusService"
                                    [keepRowNumber]="true"
                                    [herdiaDTConfig]="herdiaDTConfig"
              ></lib-herdia-datatable>
            </div>
        </div>
      <div class="card-footer">
        <lib-datatable-pagination [eventBusService]="eventBusService"></lib-datatable-pagination>
      </div>
    </div>
</div>

<ng-template  #modal>
    <div class="modal-header">
        <h4 class="modal-title"><lib-translate [key]="'modal-title-editor-user-template-email'"></lib-translate></h4>

        <a class="nav-link" data-toggle="dropdown" role="button" popoverClass="" [popoverTitle]="popTitle" [ngbPopover]="popContent">
            <fa-icon icon="circle-info"></fa-icon>
        </a>
        <ng-template #popTitle>
            <lib-translate [key]="'title-variable-information-template'"></lib-translate>
        </ng-template>
        <ng-template #popContent>
            <ul class="list-group">
                <li *ngFor="let variable of VariableEmailTemplate | keyvalue">
                    <p>{{variable.key}} : {{variable.value}}</p>
                </li>
            </ul>
            <div class="dropdown-divider"></div>
        </ng-template>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="TemplateName"><lib-translate [key]="'Name-template-email'"></lib-translate></label>
            <input type="text" class="form-control" name="TemplateName" [(ngModel)]="EmailTemplateName">
        </div>
        <angular-editor [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()"><lib-translate [key]="'button-close'"></lib-translate></button>
        <button class="btn btn-primary" (click)="UpdateOrAddEmailTemplate()"><lib-translate [key]="'button-validate'"></lib-translate></button>
    </div>
</ng-template>
